/* eslint no-undef: "warn" */
import React, { useEffect, useState } from 'react'

// Main Chakra UI components:
import {
    Box,
    Button,
    Center,
    HStack as ChakraHStack,
    Image,
    Link,
    List,
    ListItem,
    OrderedList,
    Text,
    UnorderedList,
    VStack,
} from '@chakra-ui/react'

// External libs:
import { Link as RouteLink, useLocation } from 'react-router-dom'

import 'remixicon/fonts/remixicon.css'

// Local components and data:
import useMatchMedia from './MatchMedia'
import { Glossary } from './Glossary'
import Analytics from './Analytics'
import {
    actionColors,
    cursorColors,
    ActionBar,
    HStack,
    NotePane,
    SectionHeader,
    SubsectionHeader,
    RadialImage,
    RectImage,
    style,
} from './PlayersGuideUtil'

const PlayersGuideCombat = () => {
    return (
        <VStack mt={style.sectionSpacing} spacing={style.defaultSpacing}>
            <Center h={'75px'}>
                <SectionHeader>Combat</SectionHeader>
            </Center>
            <SubsectionHeader>Anatomy of a Round</SubsectionHeader>
            <HStack spacing={style.defaultSpacing}>
                <VStack maxWidth={'50%'}>
                    <Text mb={5}>
                        Mysterious Castle is a <b>turn-based tactics game</b>. You take turns controlling each character
                        during a unit of time called a <i>round</i>. The order of the characters' turns is determined by
                        their <i>initiative</i> score plus a random factor, calculated at the beginning of combat. The
                        character or monster with the highest initiative goes first.
                        <br />
                        <br />
                        Each round can be decomposed into several action type variations. If you've played with{' '}
                        <b>D&D SRD 3.5</b> or any of it's derivatives, you'll find the structure of a round to be very
                        familar. Choosing your actions to maximize each round is the essence of tactical play in
                        Mysterious Castle!
                    </Text>
                    <NotePane style={{ width: '100%' }}>
                        <Text>
                            For example, you could take one five-foot-step towards an enemy and then <i>power attack</i>
                            .
                        </Text>
                        <ActionBar
                            pattern={[
                                [
                                    { width: '10%', type: 'free' },
                                    { width: '90%', type: 'full' },
                                ],
                            ]}
                        />
                        <Text mt={5}>
                            Or you could <i>trip</i> an enemy, and then move your full movement to a position next to an
                            ally, and <i>defend</i> them.
                        </Text>
                        <ActionBar
                            pattern={[
                                [
                                    { width: '50%', type: 'partial' },
                                    { width: '40%', type: 'move' },
                                    { width: '10%', type: 'free' },
                                ],
                            ]}
                        />
                        <Text mt={5}>
                            Or you could move your full movement, cast a <i>heal</i> spell, and move your full movement
                            again.
                        </Text>
                        <ActionBar
                            pattern={[
                                [
                                    { width: '45%', type: 'move' },
                                    { width: '10%', type: 'free' },
                                    { width: '45%', type: 'move' },
                                ],
                            ]}
                        />
                    </NotePane>
                </VStack>
                <NotePane>
                    <Text>
                        <i class='ri-information-line ri-xl'></i> Actions Types:
                    </Text>
                    <List spacing={5} mt={5}>
                        <ListItem>
                            <HStack>
                                <Image src='/playersguide/actions/long.png' />
                                <i>long action</i>
                            </HStack>
                            <Text fontWeight={400}>
                                An action that takes more than one round. You can't take long actions during combat.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <HStack>
                                <Image src='/playersguide/actions/full.png' />
                                <i>full action</i>
                            </HStack>
                            <Text fontWeight={400}>
                                An action that takes up the whole round. You may take one <i>free</i> action or one{' '}
                                <i>five-foot-step</i> before doing a full action.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <HStack>
                                <Image src='/playersguide/actions/standard.png' />
                                <i>partial action</i>
                            </HStack>
                            <Text fontWeight={400}>
                                An action that takes up a large portion of the round. You may <i>move</i> your full
                                movement before or after doing a partial action.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <HStack>
                                <Image src='/playersguide/actions/move.png' />
                                <i>move action</i>
                            </HStack>
                            <Text fontWeight={400}>
                                You can take your <i>move</i> score steps during a move action. You may take treat your
                                partial action as a second move action, so you can move your full movement * 2 spaces
                                per round. The first space you <i>move</i> each turn counts as a <i>free action</i>, and
                                won't prevent you doing a <i>full action</i>.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <HStack>
                                <Image src='/playersguide/actions/free.png' />
                                <i>free action</i>
                            </HStack>
                            <Text fontWeight={400}>
                                A very quick action. You may take one free action per round without affecting your{' '}
                                <i>action points</i>, the second free action you take counts as a <i>move action</i>.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <HStack>
                                <Image src='/playersguide/actions/instant.png' />
                                <i>instant action</i>
                            </HStack>
                            <Text fontWeight={400}>
                                An action that doesn't affect your action points. You may take any number of instant
                                actions per round.
                            </Text>
                        </ListItem>
                    </List>
                </NotePane>
            </HStack>

            <HStack>
                <VStack maxWidth={'50%'}>
                    <Text>
                        When you are in combat you'll see a health meter and action point meter for each character in
                        your party. The action point meter shows the number of steps you can take and the action types
                        available to you. As you take steps the action points in the meter will diminish. When you have
                        taken your full <i>move</i> score steps, the action point meter will reset and the color will
                        change to yellow.
                    </Text>
                    <UnorderedList>
                        <ListItem>
                            When your action point meter is <b style={{ color: actionColors.free }}>green</b> and full,
                            or only missing one point, you can take <i>full action</i>s, <i>partial action</i>s,{' '}
                            <i>move action</i>s, and <i>free action</i>
                            s.
                        </ListItem>
                        <ListItem>
                            When your action point meter is <b style={{ color: actionColors.free }}>green</b> and
                            missing two or more points you can take <i>partial action</i>s, <i>move action</i>s, and{' '}
                            <i>free action</i>
                            s.
                        </ListItem>
                        <ListItem>
                            When your action point meter is <b style={{ color: actionColors.move }}>yellow</b>, you can
                            only take <i>move actions</i> or <i>free actions</i>.
                        </ListItem>
                    </UnorderedList>
                </VStack>
                {/* <div> */}
                <RectImage
                    maxWidth={'320px'}
                    src='/playersguide/actionmeter-full.png'
                    alt='meters'
                    heading={'Full health and action point meters'}
                />
                <RectImage
                    maxWidth={'320px'}
                    src='/playersguide/actionmeter-move.png'
                    alt='meters'
                    heading={'The character can take three more steps this round'}
                />
                {/* </div> */}
            </HStack>

            <NotePane>
                <SubsectionHeader>Performing Actions</SubsectionHeader>
                <Text>
                    The actions you can perform depend on your action points, as well as your position. Each action will
                    have an icon next to it indicating the duration of the action. Actions that you can't perform due to
                    insufficient action points are <i>not shown</i> in the action menu, while actions that are
                    unavailable due to other conditions are grayed out.
                </Text>
                <HStack>
                    <Text maxWidth={'65%'}>
                        If you are next to a target and tap on them you'll be shown a menu of melee attacks. The{' '}
                        <i>spin attack</i> action will only be shown if you are adjacent to two or more enemies.
                        <br />
                        <br />
                        Each attack will have a bonus or penalty listed next to it, which depends on the weapon you are
                        using, which skill books you've read, any inherent racial modifiers, and your positioning. Most
                        items will give you a significant bonus for high ground and for flanking.
                        <br />
                        <br />
                        Take care to give appropriate weapons and equipment to your characters and focus skill books on
                        individual characters to maximize their combat potential.
                    </Text>
                    <RectImage src='/playersguide/melee-attacks.png' alt='meleeattacks' maxWidth={'370px'} />
                </HStack>
                <HStack>
                    <RectImage src='/playersguide/ranged-attacks.png' alt='meleeattacks' maxWidth={'370px'} />
                    <Text maxWidth={'65%'}>
                        When you are far from a target <i>and you have a bow or crossbow equipped</i> you'll be shown a
                        menu of ranged attacks.
                        <br />
                        <br />
                        Only elves and half-elves can shoot a bow when they are holding a weapon or shield in their
                        off-hand, other characters should equip a crossbow.
                    </Text>
                </HStack>
                <HStack>
                    <Text maxWidth={'65%'}>
                        You can <i>guard</i> an adjacent ally when you tap on them, which will put your character on
                        standby. If an enemy melee attack targets the guarded character, the guarding character will
                        step in and take the attack. If either character moves away from the other, then the guard won't
                        have time move into position. The <i>guard</i> action expires after one round.
                        <br />
                        <br />
                        Guarding your vulnerable party-members is crucial to surviving difficult encounters. Guardians
                        who have the <i>riposte</i> skill are formidable.
                    </Text>
                    <RectImage src='/playersguide/guard.png' alt='guard' maxWidth={'370px'} />
                </HStack>
                <HStack>
                    <RectImage src='/playersguide/self-actions.png' alt='meleeattacks' maxWidth={'370px'} />
                    <Text maxWidth={'65%'}>
                        You can access <i>targetted</i> and miscellaneous actions by tapping on the acting character.
                        These include the <i>cast spell</i> action, targetted powers granted by wielded equipment, as
                        well as miscellaneous actions like <i>wait</i>, <i>sneak</i>, or <i>hide</i>.
                        <br />
                        <br />
                        You can also access melee actions like <i>block</i> or <i>charge</i> by tapping on the acting
                        character. The <i>block</i> action puts your character in standby, and if any enemy enters a
                        space adjacent to them they will make an automatic <i>trip</i> attack. Minotaurs get to block 2
                        enemies per round.
                        <br />
                        <br />
                        The <i>charge</i> action allows you to run at an enemy and make a single powerful melee attack,
                        but you'll only be able to charge over unobstructed terrain in a straight line.
                    </Text>
                </HStack>
            </NotePane>
            <SubsectionHeader>Targetted Actions</SubsectionHeader>
            <HStack>
                <Text>
                    Most actions you perform are context based, which means that you perform them on the enemy or object
                    that you tapped on. For example, when you tap on an enemy and select <i>power strike</i>, you'll
                    attack the enemy you tapped on, when you tap on an item and select <i>take</i> you'll pick up the
                    item you tapped on, etc..
                    <br />
                    <br />
                    Some actions are <i>targetted</i>, which means you must select a target after initiating the action.
                    The target can be an enemy, an ally, a location, or an area, depending on the action. Most spells
                    are targetted, and actions like <i>charge</i> and <i>give</i> also require you to select a target.
                    <br />
                    <br />
                    Targetted actions will typically show cursors that you can move around by tapping on various
                    locations in the map. These cursors will be <b style={{ color: cursorColors.green }}>green</b> for
                    actions which benefit the target (like a <i>heal</i> spell),{' '}
                    <b style={{ color: cursorColors.yellow }}>yellow</b> for targetted spaces which may or may not be
                    affected by the action (like summoning <i>undead</i> in an area),{' '}
                    <b style={{ color: cursorColors.red }}>red</b> for spaces which are damaged or negatively affected
                    by the action (like a <i>blast</i> spell), and <b style={{ color: cursorColors.gray }}>gray</b> for
                    spaces which lie in the area of effect but are unaffected by the action.
                </Text>
                <NotePane style={{ maxWidth: '35%' }}>
                    <VStack>
                        <Text>
                            <i class='ri-information-line ri-xl'></i>{' '}
                            <b>
                                You can also study a tome, use a scroll, or drink a potion, directly from your inventory
                                or a container.
                            </b>
                        </Text>
                        <RectImage src='/playersguide/item-menu.png' alt='meleeattacks' maxWidth={'320px'} />
                    </VStack>
                </NotePane>
            </HStack>
            <HStack>
                <VStack>
                    <HStack>
                        <Text maxWidth={'50%'}>
                            Targetted actions require <b>two-step</b> verification, to prevent costly mistaken
                            selections. When you initially perform a targetted action, you'll see a small text prompt at
                            the top of the screen.
                            <br />
                            <br />
                            This prompt will update during the selection process to request confirmation of the target.{' '}
                            <b>At any point during the selection you can tap this prompt to cancel the action.</b>
                        </Text>
                        <RectImage
                            src='/playersguide/summon-target-1.png'
                            alt='targettedaction'
                            maxWidth={'370px'}
                            // heading={'First selection'}
                        />
                    </HStack>
                    <HStack>
                        <Text maxWidth={'50%'}>
                            After initial target selection, you'll see one or more cursors indicating the point or area
                            of effect, and the prompt will change to a confirmation message. Change the selection as
                            often as you like to get the maximum possible benefit from the action, and don't forget to{' '}
                            <i>pinch-zoom</i> on phones and tablets to select exactly what you want.
                        </Text>
                        <RectImage
                            src='/playersguide/summon-target-2.png'
                            alt='targettedaction'
                            maxWidth={'370px'}
                            // heading={'Confirm selection'}
                        />
                    </HStack>
                </VStack>
                <NotePane style={{ maxWidth: '35%' }}>
                    <i class='ri-information-line ri-xl'></i> Path Actions:
                    <VStack>
                        <Text>
                            Some targetted actions will require an unobstructed line of movement. When you select a
                            target the path will only go so far as the next obstacle. For actions such as <i>charge</i>{' '}
                            it's best to cancel the action if you can't reach your target, while for spells like{' '}
                            <i>iceray</i> you may want to cast the spell anyway.
                        </Text>
                        <RectImage
                            src='/playersguide/charge-blocked.png'
                            alt='chargeblocked'
                            maxWidth={'320px'}
                            heading={"Can't charge the target because the path is obstructed"}
                        />
                    </VStack>
                </NotePane>
            </HStack>
            <SubsectionHeader>Positioning and Cover</SubsectionHeader>
            <HStack>
                <Text maxWidth={'50%'}>
                    A fundamental property, and one of the main challenges and joys, of tactics games is positioning
                    your fighters to gain the most advantage in combat. Holding a doorway, standing on high ground,
                    flanking, taking cover, and using objects to obstruct your enemies makes the difference between
                    victory and getting slaughtered.
                </Text>
                <NotePane>
                    <i class='ri-information-line ri-xl'></i> The world of Mysterious Castle is continuous and open, so
                    battles don't take place in an arena, but rather in the same map that you explore. You can use this
                    to your advantage by luring enemies to advantageous terrain.
                </NotePane>
            </HStack>
            <Text fontSize={'l'} style={{ width: '100%' }}>
                <u>
                    <b>High Ground</b>
                </u>
            </Text>
            <HStack>
                <Text>
                    When attacking an enemy from <b>high ground</b> you will almost always get a significant bonus to
                    attack, especially when wielding pole-arms such as spears or halberds. The exception to this is when
                    you attack with very short weapons, like hand axes or daggers, in which case you will get a minor
                    attack penalty.
                </Text>
            </HStack>
            <Text fontSize={'l'} style={{ width: '100%' }}>
                <u>
                    <b>Flanking</b>
                </u>
            </Text>
            <HStack>
                <VStack style={{ maxWidth: '50%' }}>
                    <Text>
                        When you trap an enemy between two or more of your characters, they can work together to feint
                        and distract the enemy, to deal a devastating attack. Flank attacks have a bonus to both{' '}
                        <i>attack</i> <u>and</u> <i>damage</i> that increases with the number of allies that are
                        adjacent to your target. Use flank attacks whenever you can, and avoid your enemies flank
                        attacks at all costs, especially when facing mobs of enemy fighters!
                    </Text>
                    <NotePane>
                        <i class='ri-information-line ri-xl'></i> <i>Kobolds</i> gain a significant flank bonus, be
                        careful when fighting against them, and flank with them as often as possible when they are in
                        your party.
                        <br />
                        <br />
                        <i class='ri-information-line ri-xl'></i> <i>Elves</i> and <i>half-elves</i> get a penalty to
                        flanking, but allies they flank with do not.
                    </NotePane>
                </VStack>
                <RectImage
                    src='/playersguide/flanking.gif'
                    alt='chargeblocked'
                    maxWidth={'420px'}
                    // heading={"Can't charge the target because the path is obstructed"}
                />
            </HStack>
            <Text fontSize={'l'} style={{ width: '100%' }}>
                <u>
                    <b>Cover</b>
                </u>
            </Text>
            <Text>
                Sharing space with an object will grant you various degrees of <i>cover</i>, which will increase your
                armor class. Standing on a tree stump will grant you a <b>+1</b> to your <i>armor class</i>, while
                standing behind a full-grown tree will grant you <b>+3</b> to your <i>armor class</i>. Make sure to use
                cover to your advantage, because if you don't take cover, your enemies will!
            </Text>
            <SubsectionHeader>Recovering from Combat</SubsectionHeader>
            <HStack>
                <Text maxWidth={'50%'}>
                    After a difficult battle your characters <i>hitpoints</i> and spells will be depleted. In most cases
                    your party members will automatically eat <i>fruit</i> and prepare <i>readied</i> spells immediately
                    after combat. Characters whose <i>hitpoints</i> are below 90% will take turns eating fruit until
                    they are at 90%+ of their maximum health. The most damaged party members will eat fruit first,
                    followed by the next most-damaged, etc., proceeding until all party members are at 90%+{' '}
                    <i>hitpoints</i> or you run out of fruit. Characters who are able to <i>regenerate</i> do not eat
                    any fruit after combat.
                    <br />
                    <br />
                    If you don't have enough fruit to heal after a battle, you can drink <i>elixers</i> to recover your
                    lost <i>hitpoints</i>, and permanently increase your max <i>hitpoints</i>.
                    <br />
                    <br />
                    If your party is damaged and out of <i>fruit</i>, it's a wise strategy to send your <i>rogue</i> out
                    sneaking to find some.
                </Text>
                <NotePane>
                    <i class='ri-information-line ri-xl'></i> For most characters <i>hitpoints</i> are a strictly
                    limited resource, there is no automatic recovery. The exception to this is characters with the{' '}
                    <i>regenerate</i> ability. Some races (notably <i>trolls</i>) begin the game with <i>regenerate</i>,
                    but for others the only way to gain <i>regenerate</i> is by finding an extremely rare and powerful{' '}
                    <i>ring of regeneration</i>.
                    <br />
                    <br />
                    <u>Be warned!</u> Rings of regeneration are <i>cursed</i> items, so once a character equips one,
                    they can never remove it! Make sure you are equipping the character who can gain the most benefit
                    from a ring of regeneration, usually a front-line fighter who absorbs a lot of damage.
                </NotePane>
            </HStack>
        </VStack>
    )
}

export default PlayersGuideCombat
