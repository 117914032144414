import React, { useEffect, useState } from 'react'
import { Box, Button, Collapse, ListItem, UnorderedList, Text, Fade } from '@chakra-ui/react'

import Analytics from './Analytics.js'
import HelpData from './Help.js'

function Glossary({ filter, setFilter }) {
    const [highlighted, setHighlighted] = useState('')
    const [emptySearch, setEmptySearch] = useState(false)
    const [spoiled, setSpoiled] = useState({})

    useEffect(() => {
        setEmptySearch(filter && Object.entries(HelpData).filter(([k, v]) => k.includes(filter)).length == 0)
    }, [filter])

    const TermHeader = ({ id, children }) => (
        <Text as='kbd' id={id} style={{ scrollBehavior: 'smooth' }}>
            <u>
                <b>{children}</b>
            </u>
        </Text>
    )

    const DocLink = ({ link, children }) => (
        <Button
            color={'white'}
            variant='link'
            onClick={() => {
                const element = document.querySelector(link)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }}
            style={{ padding: 0, margin: 0, minWidth: 0 }}
        >
            {children}
        </Button>
    )

    const TL = ({ id, children }) => {
        const link = '#' + id
        return (
            <Button
                color={'white'}
                variant='link'
                onClick={() => {
                    setFilter('')
                    setTimeout(
                        () => {
                            const element = document.querySelector(link)
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
                                setHighlighted(id)
                            }
                            Analytics.event('help', 'click-glossary-link', id)
                        },
                        !filter ? 0 : 500
                    )
                }}
                style={{ padding: 0, margin: 0, minWidth: 0 }}
            >
                <Text as='kbd'>{children}</Text>
            </Button>
        )
    }
    const Desc = ({ children }) => <span>{children}</span>

    const Processed = ({ str }) => {
        let parts = str.props.children
        parts = parts.replace('- ', '')
        parts = parts.split(/([ ,]+)/)
        return (
            <span id={str.props.children} key={str.props.children}>
                {/* TODO: Apply this style for links that end in an 's'
                style={{ whiteSpace: 'nowrap' }}> */}
                {parts.map((p) => {
                    if (p.startsWith('$')) {
                        const id = p.slice(1).split(/[$]+/)
                        return (
                            <>
                                <TL id={id[0]}>{id[0].replace('_', ' ')}</TL>
                                {id.length > 1 && id[1]}
                            </>
                        )
                    } else if (p.startsWith('@')) {
                        const txt = p.slice(1).split(/[@]+/)
                        return <i>{txt[0].replace('_', ' ')}</i>
                    } else if (p.endsWith('@')) {
                        const txt = p.slice(0, -1).split(/[@]+/)
                        return <i>{txt}</i>
                    } else {
                        return <>{p === ',' ? p : <>{p}</>}</>
                    }
                })}
            </span>
        )
    }

    function isInViewport(el) {
        if (!el) {
            return false
        }
        const rect = el.getBoundingClientRect()
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
    }

    const Term = ({ id, name, spoiler, children }) => (
        <>
            <div style={{ position: 'relative' }}>
                <Fade
                    in={highlighted === id}
                    transition={{
                        exit: { delay: 1, duration: 1.5 },
                        enter: { delay: isInViewport(document.querySelector('#' + id)) ? 0.0 : 0.5, duration: 1.0 },
                    }}
                >
                    <Box
                        style={{
                            position: 'absolute',
                            opacity: 0.1,
                            top: 0,
                            right: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: -10,
                            borderRadius: 10,
                            backgroundColor: 'red',
                            // or:
                            // border: '8px solid red',
                        }}
                    />
                </Fade>
                <Box style={{ padding: 12 }}>
                    <TermHeader id={id.replace(' ', '_')}>{id.replace('_', ' ')}</TermHeader>{' '}
                    {name && (
                        <Desc>
                            <i>({name})</i>
                        </Desc>
                    )}
                    {spoiler && !spoiled[id] ? (
                        <UnorderedList>
                            <ListItem key={0}>
                                <Processed str={children[0]} />
                            </ListItem>
                            <ListItem key={1}>
                                <Processed str={children[1]} />
                            </ListItem>
                            <Button variant='link' onClick={() => setSpoiled({ ...spoiled, [id]: true })}>
                                Show Spoilers...
                            </Button>
                        </UnorderedList>
                    ) : (
                        <UnorderedList>
                            {(Array.isArray(children) ? children : [children]).map((c, i) => {
                                const inset = c.props.children.startsWith('-')
                                return (
                                    <ListItem key={i} id={i} style={inset ? { marginLeft: 30 } : {}}>
                                        <Processed str={c} />
                                    </ListItem>
                                )
                            })}
                        </UnorderedList>
                    )}
                </Box>
            </div>
            {/* <br /> */}
        </>
    )

    return (
        <>
            <Collapse in={emptySearch}>
                <b>No results found for </b>
                <i>{filter}</i>...
            </Collapse>

            {Object.entries(HelpData).map(([k, v]) => (
                <Collapse id={k} in={!filter || k.includes(filter)} key={k}>
                    <Term id={k} name={v.name} spoiler={v.spoiler}>
                        {v.values.map((v, i) => (
                            <div key={i}>{v}</div>
                        ))}
                    </Term>
                </Collapse>
            ))}
        </>
    )
}

export { Glossary }
