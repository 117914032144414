import ReactGA from 'react-ga4'
import { getUserId } from './IDB'

const Analytics = {
    initialize: () => {
        getUserId().then((userId) => {
            ReactGA.initialize('G-EZP5XD3E6F', {
                testMode: true,
                titleCase: false,
                gaOptions: {
                    userId: userId,
                },
            })
        })
    },

    pageview: (location, title) => {
        var location = location
        if (typeof location == 'object') {
            location = location.pathname + location.search
        }
        ReactGA.send({ hitType: 'pageview', page: location, title: title })
    },

    event: (category, action, label, value) => {
        ReactGA.event({ category: category, action: action, label: label, value: value })
    },

    // Game Events

    fullScreenEnter: () => {
        this.event('game', 'fullscreen', 'enter')
    },

    fullScreenExit: () => {
        this.event('game', 'fullscreen', 'exit')
    },

    // Help Events
    viewHelp: () => {
        this.pageview({ pathname: '/help', search: '' }, 'help')
    },

    clickHelpTopic: (topic) => {
        ReactGA.event({ category: 'help', action: 'click', label: topic })
    },

    searchGlossary: (term) => {
        ReactGA.event({ category: 'glossary', action: 'search', label: term })
    },

    clickGlossary: (term) => {
        ReactGA.event({ category: 'glossary', action: 'click', label: term })
    },

    // Feedback Events
    viewFeedback: () => {
        this.pageview({ pathname: '/feedback', search: '' }, 'feedback')
    },

    // Donate Events
    viewDonate: () => {
        this.pageview({ pathname: '/donate', search: '' }, 'donate')
    },

    // Social Events
    viewSocial: () => {
        this.pageview({ pathname: '/social', search: '' }, 'social')
    },

    // Music Events
    viewMusic: () => {
        this.pageview({ pathname: '/music', search: '' }, 'music')
    },

    playMusic: () => {
        this.eventt('music', 'play')
    },

    stopMusic: () => {
        this.event('music', 'stop')
    },
}

export default Analytics
