/* eslint no-undef: "warn" */
import React, { useEffect, useState } from 'react'

// Main Chakra UI components:
import {
    Box,
    Button,
    Center,
    HStack as ChakraHStack,
    Image,
    Link,
    List,
    ListItem,
    OrderedList,
    Text,
    UnorderedList,
    VStack,
} from '@chakra-ui/react'

// External libs:
import { Link as RouteLink, useLocation } from 'react-router-dom'

import 'remixicon/fonts/remixicon.css'

// Local components and data:
import useMatchMedia from './MatchMedia'
import { Glossary } from './Glossary'
import Analytics from './Analytics'
import {
    actionColors,
    cursorColors,
    ActionBar,
    HStack,
    NotePane,
    SectionHeader,
    SubsectionHeader,
    RadialImage,
    RectImage,
    style,
} from './PlayersGuideUtil'

const PlayersGuideNewGame = () => {
    return (
        <VStack mt={style.sectionSpacing} spacing={style.defaultSpacing}>
            <Center h={'75px'}>
                <SectionHeader>Starting a New Game</SectionHeader>
            </Center>
            <Text maxWidth={style.defaultColumnWidth}>
                On game bootup you'll land at the <b>Main Menu</b>. Mysterious Castle worlds are{' '}
                <b>procedurally generated</b>, so the first thing you'll have to do is generate a <b>New World</b>. The
                world generation process will take a little bit of time, and occasionally will restart due to
                constraints not being satisfied, but in short order you will be sent back to the main menu, where the{' '}
                <b>New Game</b> button will now be enabled.
            </Text>
            <RectImage src='/playersguide/mainmenu.png' alt='mainmenu' maxWidth={style.defaultImageWidth} />
            <Text maxWidth={style.defaultColumnWidth}>
                In the <b>New Game</b> screen you'll be presented with party-creation.
                <br />
                <br />
                The game is balanced for a party of 4 characters. The canonical party is composed of two <b>fighters</b>
                , one <b>rogue</b>, and one <b>mage</b>. When you first start the game, you'll see the default
                characters are divided into these <i>roles</i>.
            </Text>
            <HStack>
                <NotePane style={{ width: style.defaultColumnWidth }}>
                    <Text>
                        <i class='ri-information-line ri-xl'></i> There are no actual class mechanics in Mysterious
                        Castle, rather each character's abilities are determined by their <i>stats</i> and{' '}
                        <i>racial modifiers</i>. We will nevertheless refer to the party-members' <i>roles</i>. The main
                        role of the <b>fighter</b> is to absorb and dish out melee, the role of the <b>rogue</b> is to
                        sneak and use <i>sneak attack</i>s, and unlock locked chests and doors. The role of the{' '}
                        <b>mage</b> is to cast devastating spells strategically. Additionally any one of these roles may
                        also be an <b>archer</b>, who has the responsibility of attacking from a distance.
                    </Text>
                </NotePane>
                <RectImage src='/playersguide/newgame.png' alt='newgame' maxWidth={style.defaultImageWidth} />
            </HStack>
            <RectImage
                src='/playersguide/balancedparty.png'
                alt='balancedparty'
                maxWidth={style.defaultImageWidth}
                heading={
                    "If you recruit 1 character from each column, you'll be guaranteed a fairly well balanced party."
                }
            />
            <Text maxWidth={style.defaultColumnWidth}>
                You can reassign the default stats of each character by selecting <b>edit</b>. You can then{' '}
                <b>delete</b> a character and <b>create</b> new one with the basic stats assigned however you like. In
                fact, this will be essential if you create smaller a party with 3, 2, or even 1 character taking on all
                the <i>role</i>s.
                <br />
                <br />
                Note that if you create a new character then the <i>role</i> guides will disappear until you{' '}
                <b>reset all</b>.
            </Text>
            <HStack maxWidth={style.defaultColumnWidth}>
                <Text maxWidth={'50%'}>
                    To check the modifiers of each race you can enter <i>help mode</i> by tapping the <b>?</b> button at
                    the bottom left of the screen, and then tapping a race you'd like to see the info of. You can exit{' '}
                    <i>help mode</i> by tapping the <b>?</b> button again, or tapping the toast message that appears at
                    the top of the screen.
                </Text>
                <NotePane style={{ width: style.defaultColumnWidth }}>
                    <Text>
                        <i class='ri-information-line ri-xl'></i> <b>help mode</b> is a way to get in-game info about
                        any entity or action tapped. Use it often!
                    </Text>
                </NotePane>
            </HStack>
            <RectImage src='/playersguide/helpmodeparty.png' alt='helpmodeparty' maxWidth={style.defaultImageWidth} />
            <Text maxWidth={style.defaultColumnWidth}>
                You can also select your party's <b>bonus</b> level. The higher the bonus level, the better your
                starting items and spells are. The recommended level is <b>+1</b>, but you can choose a higher bonus if
                you are having difficulty with surviving the early game.
            </Text>
        </VStack>
    )
}

export default PlayersGuideNewGame
