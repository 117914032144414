/* eslint no-undef: "warn" */
import React, { useEffect, useRef, useState } from 'react'

// Main Chakra UI components:
import {
    Box,
    Button,
    Center,
    Fade,
    Flex,
    HStack,
    Image,
    Link as ChakraLink,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ScaleFade,
    SimpleGrid,
    Slide,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'

// External libs:
import { Link, Link as RouteLink, useLocation } from 'react-router-dom'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { InView } from 'react-intersection-observer'

// Icons:
import { ExternalLinkIcon } from '@chakra-ui/icons'
import 'remixicon/fonts/remixicon.css'

// Local components and data:
import { InfoDrawer } from './InfoDrawer'
import { Toolbar } from './GameToolbar'
import { GameBox } from './GameBox'
import reviews from './reviews'
import useMatchMedia from './MatchMedia'
import Analytics from './Analytics'

const Home = () => {
    // Handle GA location tracking
    const location = useLocation()
    useEffect(() => {
        Analytics.pageview(location, 'Home')
    }, [location])

    const isDesktopResolution = useMatchMedia('(min-width:992px)', true)
    const isPhoneResolution = useMatchMedia('(max-width:600px) or (max-height:600px)', true)

    const [desktopInfoOpen, setDesktopInfoOpen] = useState(false)
    const [logoShrink, setLogoShrink] = useState(0)
    const [infoState, setInfoState] = useState('help')
    const { isOpen, onOpen, onClose } = useDisclosure()

    const openSidebar = (state) => {
        setInfoState(state)
        if (window.Module) {
            window.Module.ignoreKeyboardEvents = true
        }
        onOpen()
    }

    useEffect(() => {
        window.onscroll = () => {
            setLogoShrink(window.pageYOffset)
        }
        return () => {
            window.onscroll = null
        }
    }, [])

    const containerPadding = 10

    const responsiveCarousel = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1224 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1224, min: 564 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 564, min: 0 },
            items: 1,
        },
    }

    const CarouselCard = ({ review }) => (
        <Box pl={5} pr={5}>
            <Flex
                key={review.by}
                boxShadow='rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                justifyContent='space-between'
                flexDirection='column'
                overflow='hidden'
                color='gray.300'
                bg='#222222'
                rounded={5}
                flex={1}
                p={5}
                style={{
                    userSelect: 'none',
                }}
            >
                <VStack>
                    <Text>
                        <b>{review.title}</b>
                    </Text>
                    <Text color='yellow'>
                        {new Array(review.stars).fill(0).map((_, i) => (
                            <span key={i}>
                                <i className='ri-star-fill'></i>
                            </span>
                        ))}
                    </Text>
                    <Text>{review.content}</Text>
                    <Text>
                        <i>- {review.by}</i>
                    </Text>
                </VStack>
            </Flex>
        </Box>
    )

    const InfoBox = ({ maxWidth, children }) => (
        <Box
            boxShadow='rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
            color='gray.300'
            fontWeight={600}
            bg='#222222'
            rounded={5}
            flex={1}
            p={10}
            maxWidth={maxWidth}
        >
            {children}
        </Box>
    )

    const infoPoints = [
        'Control a party of 4 characters',
        'Fast-paced tactical combat',
        'Procedurally generated worlds',
        'Easy touch interface to deep complexity',
    ]

    const PlayNowButtons = () => {
        if (!isPhoneResolution) {
            return (
                <Center>
                    <HStack mb={5}>
                        <>
                            {' '}
                            <ChakraLink as={RouteLink} to='/game'>
                                <Button m={1} size='sm' w={210}>
                                    Play In Your Browser
                                </Button>
                            </ChakraLink>
                            <Text color='white' fontSize='xs'>
                                <i>or</i>
                            </Text>
                        </>
                        <ChakraLink href='https://apps.apple.com/us/app/mysterious-castle/id465647954' isExternal>
                            <Button m={1} size='sm' w={210}>
                                Get it on the App Store
                                <ExternalLinkIcon mx='2px' />
                            </Button>
                        </ChakraLink>
                        <Text color='white' fontSize='xs'>
                            <i>or</i>
                        </Text>
                        <ChakraLink
                            href='https://play.google.com/store/apps/details?id=com.eclectocrat.mysterious_castle&hl=en_US'
                            isExternal
                        >
                            <Button m={1} size='sm' w={210}>
                                Get it on Google Play
                                <ExternalLinkIcon mx='2px' />
                            </Button>
                        </ChakraLink>
                    </HStack>
                </Center>
            )
        } else {
            return (
                <Center>
                    <HStack mb={10}>
                        <VStack>
                            <ChakraLink href='https://apps.apple.com/us/app/mysterious-castle/id465647954' isExternal>
                                <Button m={1} size='sm' w={210}>
                                    Get it on the App Store
                                    <ExternalLinkIcon mx='2px' />
                                </Button>
                            </ChakraLink>
                            <ChakraLink
                                href='https://play.google.com/store/apps/details?id=com.eclectocrat.mysterious_castle&hl=en_US'
                                isExternal
                            >
                                <Button m={1} size='sm' w={210}>
                                    Get it on Google Play
                                    <ExternalLinkIcon mx='2px' />
                                </Button>
                            </ChakraLink>
                            <VStack>
                                <Text color='white' fontSize='xs'>
                                    or
                                </Text>
                                <Button
                                    m={1}
                                    size='sm'
                                    w={210}
                                    variant={'link'}
                                    onClick={() => setDesktopInfoOpen(true)}
                                >
                                    Play&nbsp;<u>FREE</u>&nbsp;In Your Desktop Browser
                                </Button>
                            </VStack>
                        </VStack>
                    </HStack>
                </Center>
            )
        }
    }

    return (
        <div
            style={{
                backgroundColor: '#0f0f0f',
                margin: 0,
                padding: containerPadding,
            }}
        >
            <Box mt={isDesktopResolution ? 250 : 105} style={{ display: 'flex', justifyContent: 'center' }}>
                <Slide in={true} direction='top'>
                    <Center mt={50}>
                        <Image
                            src='/Logo.png'
                            alt='logo'
                            style={{
                                imageRendering: 'high-quality',
                                transition: 'all linear .50s',
                                height: isDesktopResolution ? '300px' : '160px',
                                maxWidth: '90%',
                                width: 'auto',
                            }}
                            opacity={logoShrink >= 200 ? 0.05 : 0.9}
                        />
                    </Center>
                </Slide>
                <Box style={{ width: isDesktopResolution ? '50%' : '75%', margin: '0, auto' }}>
                    <Text
                        style={{
                            color: 'white',
                            fontSize: '1.5em',
                            textAlign: 'center',
                            marginTop: 0,
                            marginBottom: 5,
                        }}
                    >
                        A <ChakraLink>tactics-roguelike</ChakraLink> adventure for iOS, Android and web
                    </Text>
                    <Text
                        style={{
                            color: '#aaaaaa',
                            fontSize: '0.75em',
                            textAlign: 'center',
                            marginTop: 0,
                            marginBottom: 10,
                        }}
                    >
                        <i>Since 2011</i>
                    </Text>
                    <PlayNowButtons />

                    <Center mb={50}>
                        <VStack>
                            <Button
                                variant={'link'}
                                onClick={() => {
                                    window.open('https://discord.gg/Td6ChnTzUH', '_blank')
                                }}
                            >
                                <Image src='discord.png' width={'80%'} />
                            </Button>
                            <Button
                                m={1}
                                size='xs'
                                w={210}
                                variant={'link'}
                                onClick={() => {
                                    window.open('https://eclectocrat.itch.io/mysterious-castle/devlog', '_blank')
                                }}
                            >
                                Follow the DevLog on&nbsp;<u>itch.io</u> <ExternalLinkIcon mx='2px' />
                            </Button>
                        </VStack>
                    </Center>

                    <Fade in={true}>
                        <Box mb={10}>
                            <Carousel
                                arrows={false}
                                renderButtonGroupOutside={true}
                                swipeable={true}
                                draggable={true}
                                responsive={responsiveCarousel}
                                infinite={true}
                                autoPlay={!isOpen}
                                autoPlaySpeed={4000}
                                keyBoardControl={true}
                                transitionDuration={500}
                                containerClass='carousel-container'
                                removeArrowOnDeviceType={['tablet', 'mobile']}
                            >
                                {reviews.map((review, i) => (
                                    <div key={i}>
                                        <CarouselCard review={review} />
                                    </div>
                                ))}
                            </Carousel>
                        </Box>
                    </Fade>
                    <Image src='/screen01.png' alt='screenshot-01' style={{ marginBottom: 40 }}></Image>

                    <InView threshold={0.5}>
                        {({ ref, inView, entry }) => (
                            <Center ref={ref}>
                                <ScaleFade in={inView} initialScale={0.9}>
                                    <InfoBox maxWidth={600}>
                                        <VStack spacing={5}>
                                            <Text>
                                                Control a party of adventurers as you make your way through a
                                                procedurally generated world filled with dangerous monsters, ancient
                                                treasures, and powerful magic. Each world is generated from scratch so
                                                you'll never play the same game twice. Classic roguelike gameplay with a
                                                tactics twist.
                                            </Text>
                                        </VStack>
                                    </InfoBox>
                                </ScaleFade>
                            </Center>
                        )}
                    </InView>
                    <Center mb={10}>
                        <SimpleGrid columns={isDesktopResolution ? 2 : 1} spacing={10} maxWidth={600} mt={10}>
                            {infoPoints.map((point, i) => (
                                <div key={i}>
                                    <InView threshold={0.5}>
                                        {({ ref, inView, entry }) => (
                                            <ScaleFade ref={ref} in={inView} initialScale={0.9}>
                                                <InfoBox>
                                                    <Text>{point}</Text>
                                                </InfoBox>
                                            </ScaleFade>
                                        )}
                                    </InView>
                                </div>
                            ))}
                        </SimpleGrid>
                    </Center>
                    <PlayNowButtons />
                </Box>
            </Box>

            {/* <GameBox /> */}

            {/* Right control bar: */}
            <Fade in={!isOpen} transition={{ enter: { duration: 1.5 } }}>
                <Toolbar
                    position='right'
                    showFullscreen={false}
                    onHelp={() => {
                        openSidebar('help')
                    }}
                    onBug={() => {
                        openSidebar('bug')
                    }}
                    onDonate={() => {
                        openSidebar('donate')
                    }}
                    onSocial={() => {
                        openSidebar('social')
                    }}
                    onWallet={() => {}}
                />
            </Fade>

            {/* Drawer with sidebar contents: */}
            <InfoDrawer
                initialSection={infoState}
                isOpen={isOpen}
                onClose={() => {
                    if (window.Module) {
                        window.Module.ignoreKeyboardEvents = false
                    }
                    onClose()
                }}
            />

            {/* Modal for version upgrade */}
            <Modal isOpen={desktopInfoOpen} onClose={() => setDesktopInfoOpen(false)} isCentered={true}>
                <ModalOverlay />
                <ModalContent bg={'#222222'}>
                    <ModalHeader textColor={'white'}>Play in your desktop Chrome</ModalHeader>
                    <ModalBody textColor={'white'}>
                        <Text>
                            You can play Mysterious Castle on a desktop Chrome browser. Visit us from your desktop to
                            play!
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={() => setDesktopInfoOpen(false)}>
                            Ok
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Box h={20} />
        </div>
    )
}

export default Home
