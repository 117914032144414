import React from 'react'

// Main Chakra UI components:
import { Box, Center, HStack as ChakraHStack, Image, Text, VStack } from '@chakra-ui/react'

// Icons:
import 'remixicon/fonts/remixicon.css'

// Local components and data:
import useMatchMedia from './MatchMedia'

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const actionColors = {
    full: '#dd0000',
    partial: '#cc4400',
    move: '#eecc00',
    free: '#02cc02',
}

export const cursorColors = {
    green: '#02cc02',
    yellow: '#eecc00',
    red: '#dd0000',
    gray: '#666666',
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const NotePane = ({ children, style }) => (
    <Box bg={'#222222'} style={{ borderRadius: 20, padding: 30, margin: 20, ...style, fontWeight: 550 }}>
        {children}
    </Box>
)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const SectionHeader = ({ children }) => (
    <Center h={'75px'}>
        <Text fontSize={'5xl'}>
            <u>{children}</u>
        </Text>
    </Center>
)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const SubsectionHeader = ({ children }) => (
    <Text fontSize={'2xl'} style={{ width: '100%' }}>
        <b>{children}</b>
    </Text>
)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const RadialImage = ({ src, alt, style }) => (
    <div
        style={{
            backgroundImage: `radial-gradient(circle at center, #00000000, #0f0f0fff 75%, #0f0f0fff), url("/playersguide/${src}")`,
            width: '400px',
            height: '400px',
            backgroundSize: 'cover',
            margin: 20,
            ...(style || {}),
        }}
    />
)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const RectImage = ({ src, alt, style, heading, maxWidth }) => (
    <div style={{ padding: 20, maxWidth: maxWidth, width: maxWidth }}>
        <Image
            src={src}
            alt={alt}
            style={{
                borderRadius: 20,
                ...(style || {}),
            }}
        />
        <div>
            {heading && (
                <Center>
                    <Text fontSize={'sm'} mt={3}>
                        <i>{heading}</i>
                    </Text>
                </Center>
            )}
        </div>
    </div>
)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ActionBar = ({ pattern }) => (
    <div style={{ width: '100%' }}>
        {pattern.map((pattern, i) => (
            <Box width={'100%'} height={'20px'} p={5}>
                <ChakraHStack>
                    {' '}
                    {pattern.map((p) => (
                        <Box
                            key={i}
                            style={{ height: '10px', width: p.width, backgroundColor: actionColors[p.type] }}
                        />
                    ))}
                </ChakraHStack>
            </Box>
        ))}
    </div>
)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const HStack = ({ children, spacing, style }) => {
    const isPhoneResolution = useMatchMedia('(max-width:600px) or (max-height:600px)', true)
    if (isPhoneResolution) {
        return (
            <VStack spacing={spacing} style={{ ...(style || {}), width: '100%' }}>
                {children}
            </VStack>
        )
    } else {
        return (
            <ChakraHStack spacing={spacing} style={{ ...(style || {}), width: '100%' }}>
                {children}
            </ChakraHStack>
        )
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const style = {
    sectionSpacing: '250px',
    defaultSpacing: '50px',
    defaultImageWidth: '640px',
    maxBodyWidth: '1024px',
    defaultColumnWidth: '60%',
}
