import { openDB } from 'idb'

const upgrade = (db) => {
    db.createObjectStore('wasm')
    db.createObjectStore('js')
    db.createObjectStore('user')
}

///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////

const getUserId = async () => {
    const db = await openDB('mysterious-castle', 1, {
        upgrade,
    })

    // Try and read userId
    const trans = db.transaction(['user'], 'readwrite')
    const store = trans.objectStore('user')
    const userIds = await store.getAllKeys().then((keys) => keys.sort())
    await trans.done

    if (userIds && userIds.length > 0) {
        return userIds[0]
    } else {
        const userId = crypto.randomUUID()
        const trans = db.transaction(['user'], 'readwrite')
        const store = trans.objectStore('user')
        await store.put(userId, userId)
        await trans.done

        return userId
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
// Load WASM from Remote Server

/**
 * Fetch the WASM file from the server, tee it to the local DB, and notify observers.
 *
 * @param {*} url
 * @param {*} options
 * @returns A response which streams the WASM file, and notifies observers.
 */
async function fetchWasm(remoteWasm, options) {
    console.info('Fetching ' + remoteWasm.file)

    const response = await fetch(remoteWasm.file, options)
    const contentLength = response.headers.get('Content-Length')
    const totalBytes = contentLength ? parseInt(contentLength, 10) : null
    var readBytes = 0

    // Split data stream in two:
    const tees = response.body.tee()

    // The other stream to feed into WebAssembly:
    var customResponse = new Response(
        new ReadableStream({
            async start(controller) {
                const reader = tees[0].getReader()
                while (true) {
                    const { done, value } = await reader.read()
                    if (done) {
                        window.dispatchEvent(
                            new CustomEvent('fetch-wasm-finished', {
                                detail: { readBytes },
                            })
                        )
                        break
                    } else {
                        readBytes += value.length
                        controller.enqueue(value)
                        window.dispatchEvent(
                            new CustomEvent('fetch-wasm-progress', {
                                detail: { readBytes, totalBytes },
                            })
                        )
                    }
                }
                controller.close()
            },
        }),
        {
            status: response.status,
            statusText: response.statusText,
            headers: new Headers({
                'Content-Type': 'application/wasm',
            }),
        }
    ) // One stream to write to IDB:
    ;(async () => {
        const buffer = await new Response(tees[1]).arrayBuffer()
        const array = new Uint8Array(buffer)
        const db = await openDB('mysterious-castle', 1, {
            upgrade(db) {
                db.createObjectStore('wasm')
                db.createObjectStore('js')
            },
        })
        const trans = db.transaction(['wasm'], 'readwrite')
        const store = trans.objectStore('wasm')
        await store.put(array, remoteWasm.version)
        await trans.done
        console.log('Saved WASM to IDB: ', remoteWasm.version)
    })()

    // Kick-off both streams:
    return customResponse
}

async function fetchJs(version) {
    console.info('Fetching JS file:', version.jsfile)

    // Fetch the src from the remote.
    const src = await fetch(version.jsfile)
    const text = await src.text()

    // Save the src to idb
    const db = await openDB('mysterious-castle', 1, {
        upgrade,
    })
    const trans = db.transaction(['js'], 'readwrite')
    const store = trans.objectStore('js')
    await store.put(text, version.version)
    await trans.done

    console.log('Saved JS to IDB: ', version.version)
    return text
}

///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
// Load WASM from IndexedDB

/**
 * Load the local WASM from the IndexedDB, if it exists. If there is a version conflict, then
 * return version info, and allow the caller to decide what to do by either updating the local
 * version, or re-calling this function passing `null` as the remoteVersions to load the current
 * local version regardless of the remote version.
 *
 * @param {*} remoteVersions The current versions file from the remote, pass `null` to load the
 *  current local version, regardless of the remote version.
 * @returns
 */
const loadLocalVersion = async (remoteVersions) => {
    const db = await openDB('mysterious-castle', 1, {
        upgrade,
    })

    const trans = db.transaction(['wasm', 'js'], 'readwrite')
    const wstore = trans.objectStore('wasm')
    const wasmVersions = await wstore.getAllKeys().then((keys) => keys.sort())
    const jstore = trans.objectStore('js')
    const jsVersions = await jstore.getAllKeys().then((keys) => keys.sort())
    await trans.done

    // Only count version that have both files present
    const versions = wasmVersions.filter((v) => jsVersions.includes(v))

    if (!versions || versions.length === 0) {
        return null
    } else {
        const localVersion = versions[versions.length - 1]

        // Compare versions (if provided) and return local version if it's outdated.
        if (remoteVersions && localVersion != remoteVersions[0].version) {
            console.info('Local WASM version is outdated')
            return { localVersion: { version: localVersion }, remoteVersion: remoteVersions[0] }
        }

        // Otherwise we either requested any version or are up-to-date.
        const trans = db.transaction(['wasm', 'js'], 'readwrite')
        const wstore = trans.objectStore('wasm')
        const wasm = await wstore.get(localVersion)
        const jstore = trans.objectStore('js')
        const js = await jstore.get(localVersion)
        await trans.done

        return { version: localVersion, wasm, js }
    }
}

const deleteLocalVersion = async (version) => {
    console.log('Deleting version ' + version)

    const db = await openDB('mysterious-castle', 1, {
        upgrade,
    })

    const trans = db.transaction(['wasm', 'js'], 'readwrite')
    const wstore = trans.objectStore('wasm')
    wstore.delete(version)
    const jstore = trans.objectStore('js')
    jstore.delete(version)
    await trans.done

    console.log('Deleted version ' + version)
}

///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
// Instantiate WASM

/**
 * Main hook for instantiating the WASM for emscripten.
 * @see https://github.com/emscripten-core/emscripten/blob/main/test/manual_wasm_instantiate.html
 */
function instantiateWasm(imports, callback) {
    console.log('InstantiateWasm()')

    const loadWasm = (wasm) => {
        WebAssembly.instantiate(wasm, imports)
            .then((output) => {
                // Enabling USE_OFFSET_CONVERTER (directly or by enabling sanitizers) breaks caller provided instantiateWasm hooks.
                // @see: https://github.com/emscripten-core/emscripten/issues/13424
                // WasmOffsetConverter
                callback(output.instance)
            })
            .catch((e) => {
                console.error('Error instantiating WASM: ', e)
            })
    }

    if (window.Module.localWasm && window.Module.localWasm.wasm) {
        console.log('Using local WASM')
        loadWasm(window.Module.localWasm.wasm)
    } else {
        console.assert(window.Module.remoteWasm, 'Remote WASM info not found')
        console.log("Fetching remote WASM from '" + window.Module.remoteWasm.file)
        fetchWasm(window.Module.remoteWasm, { credentials: 'same-origin' }).then((response) => {
            console.log('Fetching wasm from ' + window.Module.remoteWasm.file + '...')
            var result = WebAssembly.instantiateStreaming(response, imports)
            return result.then(
                (wasm) => callback(wasm.instance),
                (error) => {
                    console.error(`WASM streaming compile failed: ${error}`)
                    loadWasm(window.Module.remoteWasm.file)
                }
            )
        })
    }
    return {}
}

export { getUserId, loadLocalVersion, deleteLocalVersion, fetchJs, instantiateWasm, upgrade }
