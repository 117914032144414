import React from 'react'
import { Box, Button, Icon, Link, ListItem, OrderedList, Text, UnorderedList, useToast } from '@chakra-ui/react'

import { HederaIcon } from './HederaIcon'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import 'remixicon/fonts/remixicon.css'

function DonateInfo() {
    const toast = useToast()
    const paymentItemStyle = { marginTop: 5 }
    return (
        <Box style={{ color: 'white', overflowY: 'scroll', padding: 5, paddingTop: 0 }}>
            <Text>
                Making Mysterious Castle has been a long and winding part-time passion for almost 15 years. Most of that
                time I was unable to work on Mysterious Castle due to full-time work and financial constraints. Please
                consider supporting the project if you want to see it grow and develop further.
            </Text>
            <ul style={{ paddingLeft: 20, marginTop: 10, marginBottom: 10 }}>
                <li style={{ marginBottom: 10 }}>
                    <b>Most importantly</b> share this game with your friends
                </li>
                <li style={{ marginBottom: 10 }}>
                    Follow me on{' '}
                    <Link href='https://twitter.com/eclectocrat' isExternal color='green.300'>
                        <i className='ri-twitter-x-line'></i>/<i className='ri-twitter-line'></i>{' '}
                        <ExternalLinkIcon mx='2px' />
                    </Link>
                </li>
                <li style={{ marginBottom: 10 }}>
                    If you have an iPhone or iPad, buy the iOS native version from the{' '}
                    <Link href='' isExternal color='green.300'>
                        App Store <i className='ri-app-store-line'></i>
                        <ExternalLinkIcon mx='2px' />
                    </Link>
                </li>
                <li style={{ marginBottom: 10 }}>
                    Support development costs by donating via:
                    <ul style={{ paddingLeft: 20 }}>
                        <li style={paymentItemStyle}>
                            <Button
                                variant='link'
                                color='green.300'
                                onClick={() => {
                                    navigator.clipboard
                                        .writeText('bc1qmljzuy8urpyu6unfk08wxpjxdm2kk8ntxutlkf')
                                        .then(() => {
                                            toast({
                                                id: 'copy_bitcoin',
                                                description: 'Bitcoin address copied to clipboard',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                        })
                                }}
                            >
                                <Text mr={1}>Bitcoin</Text>
                                <i className='ri-btc-line'></i>{' '}
                                <Text ml={1} as='kbd' style={{ position: 'relative', top: '0.031em' }}>
                                    <b>bc1qmljzuy8urpyu6unfk08wxpjxdm2kk8ntxutlkf</b>
                                </Text>
                            </Button>
                        </li>
                        <li style={paymentItemStyle}>
                            <Button
                                variant='link'
                                color='green.300'
                                onClick={() => {
                                    navigator.clipboard.writeText('0.0.835567-nqwxs').then(() => {
                                        toast({
                                            id: 'copy_hedera',
                                            description: 'Hedera address copied to clipboard',
                                            status: 'success',
                                            duration: 2000,
                                            isClosable: true,
                                        })
                                    })
                                }}
                            >
                                <Text mr={1}>Hedera</Text>
                                <HederaIcon />{' '}
                                <Text ml={1} as='kbd' style={{ position: 'relative', top: '0.031em' }}>
                                    <b>0.0.835567-nqwxs</b>
                                </Text>
                            </Button>
                        </li>
                        <li style={paymentItemStyle}>
                            <Link
                                href='https://paypal.me/jjurksztowicz?country.x=CA&locale.x=en_US'
                                isExternal
                                color='green.300'
                            >
                                PayPal <i className='ri-paypal-line'></i>
                                <ExternalLinkIcon mx='2px' />
                            </Link>
                        </li>
                    </ul>
                </li>
                <li style={{ marginBottom: 10 }}>
                    Share your comments and suggestions on{' '}
                    <Link
                        href='https://discord.com/channels/1198772015079895112/1198772015541260328'
                        isExternal
                        color='green.300'
                    >
                        Discord <i className='ri-discord-line'></i> <ExternalLinkIcon mx='2px' />
                    </Link>
                </li>
            </ul>
            {/* Bottom padding: */}
            <Box style={{ height: 200 }} />
        </Box>
    )
}

export { DonateInfo }
