const help = {
    acid: {
        values: [
            '2nd level spell',
            '$partial_action',
            'a spray of corrosive acid emanates from the caster in a cone',
            'all creatures within the affected area get an @dexterity saving throw vs. 10 + arcane@ to avoid being blinded',
            'all creatures take damage equal to @arcane (to a maximum of 12) d4@, subject to ordinary energy resist',
        ],
        spoiler: 'true',
    },
    activate: {
        values: [
            'prepare an item or object so that it can be used somehow',
            'ex. activate a $warp_stone so that you can use a $warp_orb to teleport there',
        ],
    },
    arcana: {
        values: [
            "difficulty of your spell's saving throws",
            'number of missiles your spells fire',
            'amount of damage your spells deal',
            'amount of $hp your spells heal',
            'area of effect of your spells',
        ],
    },
    armor: {
        values: [
            'how likely an enemies $attack is to hit you',
            'wearing medium armor negatively affects your $sneak',
            'wearing heavy armor prevents you from $sneak$ing',
        ],
    },
    attack: {
        values: ['$partial_action', 'performs a single attack on an enemy with each weapon you wield'],
    },
    attack_stat: {
        values: [
            'how likely you are to hit an entity when attacking',
            'shows bonus for melee weapon',
            '$unequip melee weapon in right hand to see bonus for $offhand weapon',
            '$unequip all melee weapons to see bonus for ranged attacks',
        ],
        name: 'attack',
    },
    back: {
        values: [
            'tells this character to go back one position in the $follow order',
            'if used on the party $leader, the first $follower will become the $leader',
        ],
    },
    banish: {
        values: [
            '3rd level spell',
            '$partial_action',
            'affected creatures caught in the area of effect must make a saving throw vs. @arcane@ + @int@',
            'creatures who fail their saving throw are permanently sent back to their home plane',
            'affects creatures of the following types: @demon, angel, elemental, undead@',
        ],
        spoiler: 'true',
    },
    blast: {
        values: [
            '3rd level spell',
            '$full_action',
            'creatures caught in the blast radius of take @arcane d8 + arcane@ fire damage',
            'there is no saving throw',
        ],
        spoiler: 'true',
    },
    bless_party: {
        values: ['permanently increase the $hp of each party member by 4', 'heals each party member to full $hp'],
    },
    block: {
        values: [
            '$partial_action',
            'the first enemy that enters a spot adjacent to you is targeted with an $attack of opportunity',
            'if you successfully hit them, the enemy falls $prone',
            'some abilities or items can grant additional block attacks per round',
        ],
    },
    block_count: {
        values: ['the number of times you can $attack an enemy that enters a spot adjacent to you'],
        name: 'blocks per round',
    },
    bolt: {
        values: [
            '1st level spell',
            '$partial_action',
            'targets one creature with the @arcane/2 + 1 bolts@ of magical energy',
            'each bolt dealing @1d3 + arcane damage@',
            '$energy_resist applies to each bolt',
            'bolts hitting creatures standing in water will cascade to damage other nearby creatures',
        ],
        spoiler: 'true',
    },
    bolt2: {
        values: [
            '2nd level spell',
            '$partial_action',
            'targets one creature with the @arcane/2 bolts@ of magical energy',
            'each bolt dealing @1d6 + arcane damage@ and then bounces to damage a nearby enemy target (if in range)',
            '$energy_resist applies to each bolt',
            'bolts hitting creatures standing in water will cascade to damage other nearby creatures',
        ],
        name: 'bolt+',
        spoiler: 'true',
    },
    cast_spell: {
        values: ['shows the prepared spell menu, where you can choose a spell to $cast'],
    },
    charge: {
        values: [
            '$full_action',
            'move up to your $move in a straight line, and then perform a single $attack with a +2 bonus to $attack and $damage',
            '$attack$s with the weapon in your right hand',
            'polearms like spears and halberds give bonuses to charge',
        ],
    },
    charm: {
        values: [
            '3rd level spell',
            '$full_action',
            'targeted creatures must @max(intelligence, constitution-2) save vs. 10 + arcane@',
            'creatures that fail their save permanently reverse their attitude towards known entities, viewing former enemies as friends, and friends as enemies',
            'creatures who successfully save @might become confused@, their attitudes being assigned at random',
        ],
        spoiler: 'true',
    },
    climb_down: {
        values: ['descend the stairs or ladder to a new area'],
    },
    climb_up: {
        values: ['ascend the stairs or ladder to a new area'],
    },
    close: {
        values: [
            '$move_action',
            'close a door, gate, or container',
            'you can $open the door, gate, or container after closing it',
        ],
    },
    combat: {
        values: [
            'a state in which each character and enemy gets a $round to act',
            'order of combat depends on the $init of each character and enemy',
            'ends automatically after all $hostile enemies are dead',
            'ends automatically if you do not see any $hostile enemies for 2 rounds',
        ],
    },
    con: {
        values: ['$hp', '$darkvision', 'saving throws for some spells'],
        name: 'constitution',
    },
    confirm_action: {
        values: [
            'a setting that controls how selection is handled, found in the $game_settings view',
            'can be one of:',
            '- @tap@: the first time you tap is your selection',
            '- @tap twice@: every selection must be confirmed with a second tap',
            '- @tap twice in combat@: every selection must be confirmed with a second tap, but only in $combat',
            '@tap twice in combat@ is recommended to prevent accidental actions in $combat, while keeping world exploration faster',
        ],
    },
    critical: {
        values: [
            'weapons have critical hit ranges and multipliers',
            '- @ex.@ 19-20/x2 means that a roll of 19 or 20 is a critical hit, and the $damage is doubled',
            'some $attack$s like $power_strike and $snipe increase your critical hit range',
        ],
    },
    curse: {
        values: [
            '3rd level spell',
            '$move_action',
            'affected creatures must make a series of @combined intelligence and constitution save vs. arcane x 2@',
            'creatures who pass the saving throw by 10 or more suffer no ill effects',
            'others are affected by a variety of permanent curses, including loss of movement, attack, and armor scores',
        ],
        spoiler: 'true',
    },
    cursed: {
        values: ['cursed items cannot be $unequip$ped'],
    },
    damage: {
        values: [
            'how much damage you do when attacking',
            'character screen shows bonus for melee weapon in right hand',
            '$unequip melee weapon in right hand to see bonus for $offhand weapon',
            '$unequip all melee weapons to see bonus for ranged attacks',
        ],
    },
    damage_resist: {
        values: ['reduces the damage from every physical hit received'],
    },
    darkvision: {
        values: [
            'how well you see in the dark',
            'affects your light radius, especially when in $sneak$ mode',
            'impacts how well you can $spot enemies or $secret_door$s in low light spaces',
        ],
    },
    defend: {
        values: [
            '$free_action',
            'the first time a defended ally is melee attacked, if the defender is no more that one step away from them, they will switch places with the defended character and take the attack',
            "you can't defend more than one strike per $round",
            "you can't defend a character more than one step away",
            "you can't defend a character if you are $prone",
        ],
    },
    dex: {
        values: ['$armor', '$init', '$sneak', 'your ranged $attack bonus', 'saving throws for some spells'],
        name: 'dexterity',
    },
    drain: {
        values: [
            '2nd level spell',
            '$full_action',
            'steals @(arcane + 1)/2 d8 + arcane@ hitpoints from a target, and gives them as temporary hitpoints to nearby allies',
        ],
        spoiler: 'true',
    },
    drink: {
        values: [
            'usually a $partial_action or $move_action',
            "consume this liquid, benefiting, or suffering from it's effects",
        ],
    },
    drop: {
        values: [
            '$free_action',
            'drops an item from your $equipment or $inventory on an adjacent tile',
            'not available if there is no free tile nearby',
            'use $put if you want to place something on a specific or more distant tile',
        ],
    },
    eat: {
        values: [
            'usually a $long_action, but eating small things is a $partial_action',
            "consume this food, benefiting, or suffering from it's effects",
        ],
    },
    energy: {
        values: [
            'a type of $damage dealt by most spells and some weapons',
            '$energy_resist reduces the amount of energy damage you receive',
        ],
    },
    energy_resist: {
        values: [
            'reduces the damage from every $energy hit received',
            'most spells primary damage is $energy',
            'applies to each missile of a spell like $bolt or $fireball',
            'stacks with $fire_resist for spells that deal $fire damage',
        ],
    },
    equip: {
        values: [
            '$partial_action',
            'put an item in an equipment slot, $unequip$ping whatever was there before',
            'you can $unequip an item to put it back in your $inventory',
            '$cursed items cannot be $unequip$ped',
        ],
    },
    equip_offhand: {
        values: [
            '$partial_action',
            'put a light weapon in your offhand (shield slot)',
            "you can't cast spells or shoot a bow while wielding something in your offhand, unless you are a half-elf",
        ],
    },
    extinguish: {
        values: [
            '$move_action',
            'extinguishes this light source',
            'provokes nearby creatures to make spot checks, which my reveal hiding characters.',
        ],
    },
    fire: {
        values: [
            'a type of $damage dealt by some spells an weapons',
            '$fire_resist reduces the amount of fire damage you receive',
            'some enemies are $immune to fire',
            'some items make you $immune to fire',
        ],
    },
    fireball: {
        values: [
            '2nd level spell',
            '$partial_action',
            'targets one creature with the @arcane/3 + 1 bolts@ of fire',
            'each bolt deals @2d6 + arcane damage@',
            '$energy_resist and $fire_resist applies to each bolt',
        ],
        spoiler: 'true',
    },
    fire_resist: {
        values: [
            'reduces the damage from every $fire hit received',
            'stacks with $energy_resist',
            'applies to each missile of a spell like $fireball',
        ],
    },
    flash: {
        values: [
            '1st level spell',
            '$partial_action',
            'a blast of powerful light emanates from a chosen point in a @radius of arcane/2@',
            'living creatures within the affected area get an @intelligence saving throw vs. 8 + arcane@ to avoid being blinded',
            'undead creatures take damage equal to @arcane/2 (to a maximum of 7) d6@, subject to ordinary $energy_resist',
            'some undead creatures suffer a permanent reduction in their $damage_resist',
        ],
        spoiler: 'true',
    },
    flip: {
        values: [
            '$move_action',
            'toggles the switch position',
            'individual switches trigger different events, often they open a secret door',
        ],
    },
    flurry: {
        values: [
            '$full_action',
            'perform a series of $attack$s each with an increasing penalty to $attack and $damage',
            'as your flurry improves, you gain additional @attack$s',
            'the flurry bonus from both your main and $offhand weapons apply',
        ],
    },
    follow: {
        values: [
            'tells this character to follow the party $leader',
            'use the $wait action to stop following',
            'use the $back action to arrange the follow order',
        ],
    },
    free_action: {
        values: [
            'an action taking up the free portion of your $round',
            'you can take a free action and still take a $move_action, $partial_action, or $full_action',
        ],
    },
    full_action: {
        values: [
            'an action taking up the full portion of your $round',
            'you can take a $free_action @before@ a full action',
            'your $round ends after a full action',
        ],
    },
    gather: {
        values: [
            'collect all party members, and start following the party $leader',
            'exits $solo mode',
            'party members that are too far away to gather will $wait',
        ],
    },
    get_up: {
        values: ['$move_action', 'stand up from a $prone position'],
    },
    give: {
        values: ['$partial_action', 'give an item to a nearby party member'],
    },
    golem: {
        values: [
            '3rd level spell',
            '$full_action',
            'summons @casters arcane/5@ earth elemental servants in an area you designate',
            'the golems will automatically attack your enemies',
        ],
        spoiler: 'true',
    },
    heal: {
        values: [
            '2nd level spell',
            '$free_action',
            'instantly heals one target for @arcane/2 d8 + arcane + intelligence@ hitpoints',
            'target gains @arcane/2 d4 temporary hitpoints',
            'removes any negative effects such as impaired movement on the target',
        ],
        spoiler: 'true',
    },
    heal_all: {
        values: [
            '3rd level spell',
            '$full_action',
            'instantly heals every friendly character in range for @arcane/2 d4 + arcane + intelligence@ hitpoints',
        ],
        spoiler: 'true',
    },
    hide: {
        values: [
            '$partial_action',
            'only available while $sneak$ing',
            'try to hide during combat so you can surprise enemies with another sneak attack',
            'you have a better chance of hiding from enemies if you are in darkness, in cover, or hidden behind trees or walls',
            'some intelligent enemies will still be able to predict your location',
        ],
    },
    hp: {
        values: [
            'expressed a ratio between @current@/@max@',
            'your @current@ hitpoints will be reduced by any enemies attacks that bypass your: $armor, $shield, $damage_resist, $energy_resist, and $fire_resist',
            'you will automatically eat $fruit to increase your @current@ hitpoints',
            'you can $drink an $elixir to increase both your @current@ and @max@ hitpoints',
            'you can cast a spells or use wands and staffs, to increase your @current@ hitpoints',
            '$regenerate will increase your hitpoints by a set amount every round',
        ],
        name: 'hit points',
    },
    iceray: {
        values: [
            '2nd level spell',
            '$partial_action',
            'creatures in the path of the ray take @arcane (to a maximum of 10) d8 damage@',
            'damage is halved with a successful @dexterity save vs. 10 + arcane@',
            'puddles of water are left in the path of the icreay',
        ],
        spoiler: 'true',
    },
    ignite: {
        values: [
            '$full_action',
            'ignites this light source, casting light on the surrounding area',
            'nearby creatures are aroused, provoking immediate spot checks which may reveal hiding characters',
        ],
    },
    immune: {
        values: [
            'you are immune to the effect or $damage type',
            'many creatures are immune to certain effects or $damage types',
            'some items grant immunity to certain effects or $damage types',
        ],
    },
    init: {
        values: ['your position in the $combat order'],
        name: 'initiative',
    },
    int: {
        values: ['your @max@ spell level', '$arcana', '$pick_lock', 'saving throws for some spells'],
        name: 'intelligence',
    },
    inventory: {
        values: ['each party member can carry up to 3x5 (15) items'],
    },
    key: {
        values: [
            'an item that unlocks a door or container',
            'you can see your collection of keys in the $keyring item that one of your characters starts with',
        ],
    },
    keyring: {
        values: [
            "an item which holds all the unused $key$s you've collected",
            'using a $key removes it from the keyring',
        ],
    },
    leader: {
        values: [
            'the character that you control out of $combat',
            'other characters $follow the leader',
            'use the $back action to arrange your party $follow order',
        ],
    },
    learn_spell: {
        values: [
            '$long_action',
            'adds this spell to the spellbook of one characters able to learn it, or levels up the spell if the character already knows it',
            'the more powerful the spell, the higher the $int score needed to learn it',
        ],
    },
    long_action: {
        values: ['an action which takes a longer than a round', "you can't perform a long action during $combat"],
    },
    move_action: {
        values: [
            'an action taking up the movement portion of your round',
            'you can take a number of steps equal to your $move during a move action',
            'you can take a move action as long as you still have at least one step remaining in your $move_meter',
            '- @ex.@ if you have a $move of 5, you can take 4 steps and then perform a move action, like $drink$ing a $vial',
            'see $round for the full action breakdown',
        ],
    },
    move_meter: {
        values: [
            'a meter which appears over your characters in $combat',
            'each @pip@ represents one step',
            'while the meter remains @green@ you can still take a $partial_action',
            'when the meter is yellow, you can only take a $free_action, or a $move_action',
        ],
    },
    move_item: {
        values: ['$free_action', 'moves this item to another $inventory slot, or to another party members $inventory'],
    },
    offhand: {
        values: [
            'a small weapon that you wield in your left hand',
            'grants a second $attack when you perform a $flurry, $power_strike, or regular $attack',
            "you can't wield a shield when holding an offhand weapon",
            "unless you are an elf or half-elf, you can't shoot a bow when holding an offhand weapon",
        ],
    },
    open: {
        values: [
            '$move_action',
            'open a door, gate, or container',
            'you can $close the door, gate, or container after opening it',
            'some doors and containers are $locked, and you must $pick_lock or have a $key to open them',
        ],
    },
    partial_action: {
        values: ['an action taking up the partial portion of your round', 'see $round for the full action breakdown'],
    },
    passive: {
        values: [
            'the party member with the highest bonus of a passive ability will perform the action',
            "items which provide the highest passive bonus will automatically be used, it's not necessary to equip them or move them to one character's inventory",
            '- @ex.@ the party member with the highest $pick_lock skill will attempt to pick a lock, and all the items in your party granting the highest $pick_lock bonus will be applied to the action, regardless of who is carrying or equipping them',
        ],
    },
    pick_lock: {
        values: [
            'unlock a locked door or container',
            "locks have pick level, and you must have a pick lock skill equal to or greater than the lock's pick level",
            '$passive skill',
        ],
    },
    portal: {
        values: [
            '3rd level spell',
            '$move_action',
            'a targeted creature is teleported to anywhere the caster designates',
            'if the destination is the targets current location the target is teleported randomly no closer than @10 + arcane@ spaces away and no further than @(10 + arcane)x2@ spaces away',
            'unwilling creatures can @intelligence save vs. 10 + arcane@ to reduce the distance of the teleportation',
            'there is a small chance that the teleportation results in exchanging places with another creature',
            'some creatures are $immune to portal',
        ],
        spoiler: 'true',
    },
    power_strike: {
        values: [
            '$full_action',
            'perform a single attack with each weapon you wield',
            'each attacks gain a bonus to $attack, $damage, and $critical',
        ],
    },
    prompt: {
        values: [
            'a request to make a selection',
            'a prompt toast will appear at the top of your screen',
            'tapping this toast at any time will cancel the prompt',
            'tap on a world space, or $inventory location, or a party member to make a selection',
            'when selecting a world space, affected spaces of the action which initiated the prompt will be highlighted',
            'prompts will either take the first input given, or request that you confirm your selection, depending on $game_settings $confirm_action',
        ],
    },
    prone: {
        values: [
            'prone creatures must $get_up before they can move or do any other actions',
            'you can switch spaces with a prone enemy',
            'prone creatures have an $armor of 0',
        ],
    },
    psybomb: {
        values: [
            '1st level spell',
            '$partial_action',
            'all enemies near the spellcaster are blasted with psychic energy, dealing @power/3 d6 + power@ damage',
            'creatures that fail a @10 + power@ strength or dexterity save, are tripped and take an additional @d4@ damage',
        ],
        spoiler: 'true',
    },
    push: {
        values: [
            '$partial_action',
            'targets one creature',
            'after targetting select a direction to push the target',
            'targeted creatures are knocked $prone and pushed $arcana/2 squares or until an obstacle is hit',
            'for each square the creature traverses in this way it takes d6 $physical damage, subject to ordinary $damage_resist',
            'if the creature is thrown into a fixed object, like a wall, it takes Xd6 damage, where X equals the number of squares it would have kept moving had it not been blocked',
            'if a creature is thrown into another creature, both creatures take damage and the second creature must $dex save vs. 9 + $arcane or be knocked $prone',
            'targets can resist the effects with a successful $str save vs. 12 + $arcana and instead take $arcana - 1 (to maximum of 10) d6 $physical damage and possibly be knocked $prone',
        ],
    },
    put: {
        values: ['$move_action', 'place an item from your $inventory or $equipment on a nearby tile you choose'],
    },
    quicken: {
        values: [
            '1st level spell',
            '$free_action',
            'the spell casters round ends immediately and the targeted creature begins a new round, outside of normal initiative',
            'the spell caster can target themselves to take another turn',
        ],
        spoiler: 'true',
    },
    rapid_shots: {
        values: [
            '$full_action',
            'fire a series of shots at a single target with an increasing $attack penalty',
            'as your rapid shots improves, you gain additional @attack$s',
        ],
    },
    regenerate: {
        values: [
            'increases your @current@ $hp by a set amount every round',
            'items which grant regenerate will often be $cursed',
        ],
    },
    read: {
        values: ['displays the written contents of this item or object'],
    },
    read_world_map: {
        values: [
            "displays a map of the overworld which displays all the areas you've explored",
            "your party's location is indicated in green",
            'doors and stairs are indicated in red',
            'other landmarks are indicated in other colors',
        ],
    },
    round: {
        values: [
            'a round is broken down into 4 parts: $free_action, $move_action, $partial_action, and $full_action',
            'you can perform one $free_action per round, and still perform any other actions',
            'the second $free_action in a round will cost you a $move_action',
            'you can perform one $move_action per round, and still perform a second $move_action, a $free_action, or a $partial_action',
            'the second $move_action in a round will cost you a $partial_action',
            'you can perform one $partial_action per round, and still perform a $free_action, or a $move_action',
            'you can perform one $full_action per round, and still perform a $free_action',
        ],
    },
    secret_door: {
        values: [
            'a door that looks like a wall',
            'your $spot skill determines how likely you are to see a secret door',
            '$spot$ted secret doors will be highlighted in @green@',
        ],
    },
    set_actor: {
        values: [
            'selecting a non-$leader characters name sets them as the character you control',
            'when not in $solo mode sets the character as the party $leader',
        ],
    },
    set_leader: {
        values: ['sets the character as the party $leader'],
    },
    shield: {
        values: [
            'grants you $temp_hp up to your shield bonus',
            'shield recharges your $temp_hp by one point every round',
        ],
    },
    shield_spell: {
        values: ['1st level spell', '$move_action', 'target gains @arcane/2 d6@ temporary hitpoints'],
        name: 'shield',
        spoiler: 'true',
    },
    shoot: {
        values: [
            '$partial_action',
            'fire a single shot at an enemy within range',
            "you can't shoot enemies adjacent to you",
            "you can't shoot enemies that you can't see",
        ],
    },
    sneak: {
        values: [
            'enters @sneak mode@',
            'sneaking characters:',
            '- extinguish light sources they carry',
            '- have -1 $movement (except for $halfling$s)',
            'in @sneak@ mode, enemies only see you if they succeeed at a $spot check against your @sneak@ skill',
            'characters in low light have a lower chance of being spotted',
            'characters in high light have a much higher chance of being spotted',
            'the first melee attack on an enemy that has not spotted you gains a large bonus to $attack and $damage which increases with your @sneak@ score',
            'enemies that are not mindless will warn their allies when they $spot a sneaking enemy, although there is usually a one round delay before the warned allies register your location',
            'you may use the $hide action during combat to force enemies to make new $spot checks',
            "characters wearing heavy armor can't sneak",
        ],
    },
    snipe: {
        values: [
            '$full_action',
            'fire a single shot at an enemy within range',
            '$attack gains a bonus to hit, $damage, and $critical',
            "you can't $shoot enemies adjacent to you",
        ],
    },
    spin_attack: {
        values: [
            '$full_action',
            '$attack each adjacent enemy with a fixed penalty to $attack and $damage',
            '$attack$s with the weapon in your right hand',
        ],
    },
    solo: {
        values: [
            'a mode where characters do not follow the party $leader',
            'useful for:',
            '- tactically positioning each character before opening a door',
            '- sending a character with high $move to draw enemies into a trap',
            '- exploring an area with a $sneak$ing character',
            'use $gather to exit solo mode',
        ],
    },
    spot: {
        values: [
            'ability to spot enemies, secret doors, and treasure',
            'modified by the amount of light, and your $darkvision score',
            'you have a chance of spotting enemies health',
            'spotted things will be highlighted in @green@ for a treasure and $secret_door$s, @yellow@ for enemies that have not spotted you, and @red@ for enemies that have spotted you.',
        ],
    },
    stable: {
        values: [
            'how well you resist being knocked $prone from $trip_attack$s',
            'how well you resist being knocked $prone from various magical effects',
            'how well you resist the $push spell',
        ],
    },
    stop_sneaking: {
        values: [
            'exits @sneak mode@ and re-$ignite$s any light sources you carry',
            'you can $sneak to re-enter @sneak mode@',
        ],
    },
    str: {
        values: ['melee $attack bonus', 'melee $damage bonus', 'saving throws for some spells'],
        name: 'strength',
    },
    study: {
        values: [
            'result varies with the entity studied',
            "when studying books, you're presented with a $prompt to choose a character",
            'the chosen character will receive a permanent bonus to a $stat, $skill, or $combat_ability',
            'books will disappear after being studied',
        ],
    },
    take: {
        values: [
            'put item from a container or the ground into your $leader$s $inventory',
            'use $give if your $leader has no more space in their $inventory',
        ],
    },
    take_all: {
        values: ['puts all items in a container into your $leader$s $inventory'],
    },
    temp_hp: {
        values: [
            'special hit points that are lost before your @current@ $hp',
            'some spells grant temporary hit points',
            '$shield grants temporary hit points',
            'not restored by $regenerate',
            'appear yellow in your $hp meter',
        ],
        name: 'temporary hit points',
    },
    trip_attack: {
        values: [
            '$partial_action',
            'targets one creature with an $attack',
            'if the attack hits, target makes a $stable check',
            'targets that fail the save are knocked $prone',
        ],
    },
    undead: {
        values: [
            '2nd level spell',
            '$full_action',
            'summons @casters arcane/4@ skeleton servants in an area you designate',
            'the quality of the skeletons summoned increases with the casters $arcane',
        ],
        spoiler: 'true',
    },
    unequip: {
        values: [
            '$partial_action',
            'remove an item from an equipment slot and put it in your $inventory',
            '$cursed items cannot be unequipped',
        ],
    },
    unlock: {
        values: ['unlocks a door or container using a $key'],
    },
    vision: {
        values: [
            '1st level spell',
            '$free_action',
            'reveals secrets near the caster, no further than @5 + arcane + max(darkvision, spot) spaces@ away',
            'enemies are outlined, and those within the line of sight have their health displayed',
            'secret doors and containers with items are outlined in green',
            "note that this spell will provide a huge bonus to your $spot check, but won't necessarily reveal everything",
        ],
        spoiler: 'true',
    },
    wait: {
        values: [
            "in $combat ends the current character's $round",
            'out of $combat tells a character to stop following the party $leader',
        ],
    },
    warp: {
        values: [
            'teleport the whole party to the last activated $warp_stone',
            'when warping while next to a warp stone, teleport the whole party to the previously activated $warp_stone',
        ],
    },
    warp_orb: {
        values: [
            "an item which you can use to $warp to $warp_stone$s you've $activate$d",
            "your party will start with a warp orb, but you won't be able to use it until you've $activate$d at least one $warp_stone",
        ],
    },
    warp_stone: {
        values: [
            'a large monolith which you can $warp to using your $warp_orb',
            'must be $activate$d before you can $warp to it',
        ],
    },
    web: {
        values: [
            '1st level spell',
            '$partial_action',
            'covers an area of @radius arcane/3@ with sticky webs',
            'any creature passing through a webbed space must make a saving throw using the higher of their @strength or dexterity stats vs. 10 + arcane@',
            'creatures who fail their save lose action points and may end their turn',
            'fire destroys webs',
        ],
        spoiler: 'true',
    },
}
export default help
