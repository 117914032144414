import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Fade,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    ScaleFade,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react'

import { Toolbar } from './GameToolbar'
import { HelpInfo } from './HelpInfo'
import { ReportABugInfo } from './ReportABugInfo'
import { DonateInfo } from './DonateInfo'
import { SocialInfo } from './SocialInfo'
import Analytics from './Analytics'

function InfoDrawer({ initialSection, isOpen, onOpen, onClose }) {
    const [section, setSection] = useState(initialSection)
    const [termFilter, setTermFilter] = useState('')

    // TODO: The onScroll event in help is not working, so forget about this for now.
    const [isHelpScrolled, setIsHelpScrolled] = useState(true)

    const sections = {
        help: 'help',
        bug: 'feedback',
        donate: 'financial',
        social: 'social',
    }

    useEffect(() => {
        setSection(initialSection)
        if (sections[initialSection]) {
            Analytics.pageview(`/${sections[initialSection]}`)
        }
    }, [initialSection])

    const linkColor = '#718096'

    return (
        <Drawer onClose={onClose} isOpen={isOpen} size={'lg'}>
            <DrawerOverlay />
            <DrawerContent style={{ backgroundColor: '#0f0f0f', color: 'white' }}>
                <DrawerCloseButton />
                <DrawerHeader>
                    <HStack w={'95%'} h={30}>
                        {
                            <Text w={section === 'help' ? '65px' : '100%'}>
                                {
                                    {
                                        help: 'Help',
                                        bug: 'Feedback',
                                        donate: 'Support Further Development',
                                        social: 'Connect with Mysterious Castle',
                                    }[section]
                                }
                            </Text>
                        }
                        {section == 'help' && (
                            <InputGroup>
                                <InputLeftElement>
                                    <i className='ri-search-line'></i>
                                </InputLeftElement>
                                <Input
                                    w={'100%'}
                                    placeholder='Filter terms'
                                    value={termFilter}
                                    onChange={(e) => setTermFilter(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Escape') {
                                            setTermFilter('')
                                            e.preventDefault()
                                            e.stopPropagation()
                                            Analytics.event('help', 'clear-filter-glossary')
                                        }
                                    }}
                                />
                                <InputRightElement>
                                    {termFilter.length > 2 && (
                                        <Button
                                            variant='link'
                                            onClick={() => {
                                                setTermFilter('')
                                                Analytics.event('help', 'clear-filter-glossary')
                                            }}
                                            fontWeight={400}
                                            mr={10}
                                        >
                                            {/* <i className='ri-close-line'></i> */}
                                            CLEAR
                                        </Button>
                                    )}
                                </InputRightElement>
                            </InputGroup>
                        )}
                    </HStack>
                </DrawerHeader>
                <DrawerBody style={{ paddingTop: 0, marginTop: 0 }}>
                    <Toolbar
                        position='left'
                        top={44}
                        onHelp={() => setSection('help')}
                        onBug={() => setSection('bug')}
                        onDonate={() => setSection('donate')}
                        onSocial={() => setSection('social')}
                        selected={section}
                    />
                    <Box id='help-content' style={{ marginLeft: 60, marginTop: 0, padding: 0 }}>
                        {section === 'help' && (
                            <HelpInfo
                                termFilter={termFilter}
                                setTermFilter={setTermFilter}
                                setIsHelpScrolled={setIsHelpScrolled}
                            />
                        )}
                        {section === 'bug' && <ReportABugInfo />}
                        {section === 'donate' && <DonateInfo />}
                        {section === 'social' && <SocialInfo />}
                    </Box>
                    <Fade in={section == 'help' && isHelpScrolled}>
                        <Box
                            style={{
                                height: '30px',
                                width: '40px',
                                position: 'fixed',
                                bottom: '0',
                                left: '0',
                                zIndex: '999999',
                                margin: 19,
                                marginBottom: '8px',
                            }}
                        >
                            <Tooltip label={'Scroll to top'} aria-label={'Scroll to top'}>
                                <Button
                                    variant='link'
                                    onClick={() => {
                                        document
                                            .querySelector('#contents-index')
                                            .scrollIntoView({ behavior: 'smooth', block: 'nearest' })
                                    }}
                                >
                                    <i className='ri-arrow-up-line ri-lg'></i>
                                </Button>
                            </Tooltip>
                        </Box>
                    </Fade>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export { InfoDrawer }
