/* eslint no-undef: "warn" */
import React, { useEffect, useState } from 'react'

// Main Chakra UI components:
import { Box, Center, Image, Link, Text, VStack } from '@chakra-ui/react'

// External libs:
import { Link as RouteLink, useLocation } from 'react-router-dom'

import 'remixicon/fonts/remixicon.css'

// Local components and data:
import useMatchMedia from './MatchMedia'
import { Glossary } from './Glossary'
import Analytics from './Analytics'
import { NotePane, RadialImage, style } from './PlayersGuideUtil'
// Sections
import PlayersGuideIntro from './PlayersGuideIntro'
import PlayersGuideStarting from './PlayersGuideStarting'
import PlayersGuideFirstSteps from './PlayersGuideFirstSteps'
import PlayersGuideCombat from './PlayersGuideCombat'
import PlayersGuideMagic from './PlayersGuideMagic'
import PlayersGuidePoweringUp from './PlayersGuidePoweringUp'

const PlayersGuide = ({ onEnter, onExit }) => {
    // Handle GA location tracking
    const location = useLocation()
    useEffect(() => {
        Analytics.pageview(location, 'PlayersGuide')
        if (location.pathname === '/players-guide') {
            onEnter()
        }
    }, [location])

    useEffect(() => {
        return () => {
            onExit()
        }
    }, [])

    const isDesktopResolution = useMatchMedia('(min-width:992px)', true)

    return (
        <Center>
            {/* BACKGROUND */}
            <div
                style={{
                    color: '#cccccc',
                    backgroundColor: '#0f0f0f',
                    margin: 100,
                    maxWidth: style.maxBodyWidth,
                }}
            >
                {/*********************************************************************************************************
                 * HEADER
                 *********************************************************************************************************/}
                <Center mt={50}>
                    <Link as={RouteLink} to='/'>
                        <Image
                            src='/Logo.png'
                            alt='logo'
                            style={{
                                imageRendering: 'high-quality',
                                transition: 'all linear 50s',
                                height: isDesktopResolution ? '300px' : '200px',
                                maxWidth: '90%',
                                width: 'auto',
                            }}
                            opacity={0.9}
                        />
                    </Link>
                </Center>
                <Center mt={50}>
                    <VStack>
                        <Text fontSize={'xxl'}>
                            <b>Players Guide v1.61</b>
                        </Text>
                        <NotePane>
                            <Text fontSize={'sm'}>
                                <span style={{ color: 'red' }}>
                                    <i class='ri-information-line ri-xl'></i>
                                </span>{' '}
                                <i>
                                    This section is{' '}
                                    <u>
                                        <b>UNDER CONSTRUCTION</b>
                                    </u>
                                    , it's missing information and some information may be different after final
                                    release.
                                </i>
                            </Text>
                        </NotePane>
                    </VStack>
                </Center>

                {/*********************************************************************************************************
                 * CONTENT
                 *********************************************************************************************************/}

                <PlayersGuideIntro />

                <PlayersGuideStarting />

                <Center mt={style.defaultSpacing}>
                    <RadialImage src='darkforest03.png' />
                </Center>

                <PlayersGuideFirstSteps />

                <Center mt={style.defaultSpacing}>
                    <RadialImage src='darkforest04.png' />
                </Center>

                <PlayersGuideCombat />

                <Center mt={style.defaultSpacing}>
                    <RadialImage src='tome.png' />
                </Center>

                <PlayersGuideMagic />

                <Center mt={style.defaultSpacing}>
                    <RadialImage src='adventurers01.png' />
                </Center>

                <PlayersGuidePoweringUp />

                {/*********************************************************************************************************
                 * GLOSSARY
                 *********************************************************************************************************/}

                <Box mt={200}>
                    <Text fontSize={'4xl'}>
                        <u>Glossary of Terms</u>
                    </Text>
                    <Glossary filter={null} setFilter={() => null} />
                </Box>
                <Box h={20} />
            </div>
        </Center>
    )
}

export default PlayersGuide
