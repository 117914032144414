/* eslint no-undef: "warn" */
import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Center,
    Fade,
    Link,
    List,
    ListIcon,
    ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Progress,
    ScaleFade,
    Text,
    VStack,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'

import { loadLocalVersion, deleteLocalVersion, fetchJs, instantiateWasm } from './IDB'

// import ReactGA from 'react-ga'

import { ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons'
import 'remixicon/fonts/remixicon.css'

import { InfoDrawer } from './InfoDrawer'
import { Toolbar } from './GameToolbar'
import { ReleaseNotes } from './ReleaseNotes'

const Game = ({ shouldStart, onGameStart, shouldHide }) => {
    const toast = useToast()

    // State:
    const [isLoaded, setIsLoaded] = useState(false)
    const [loadingProgress, setLoadingProgress] = useState(0.0)
    const [showLoading, setShowLoading] = useState(true)
    const [showPreamble, setShowPreamble] = useState(false)
    const [showZoomInfo, setShowZoomInfo] = useState(true)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [infoState, setInfoState] = useState('help')
    const [updateVersion, setUpdateVersion] = useState(null)
    const [updateAction, setUpdateAction] = useState(null)
    const [filesystemInitialized, setFilesystemInitialized] = useState(false)
    const [runtimeInitialized, setRuntimeInitialized] = useState(false)

    const openSidebar = (state) => {
        setInfoState(state)
        window.Module.ignoreKeyboardEvents = true
        onOpen()
    }

    // Actually kick-off the emscripten process:
    const injectGame = async (localWasm, remoteWasm) => {
        window.Module.localWasm = localWasm
        window.Module.remoteWasm =
            // DEBUG fetch local
            // = {
            //    file: '/mysterious-castle.wasm',
            //    jsfile: '/mysterious-castle.js',
            //    version: '9.99.9',
            // }
            remoteWasm

        // Get the js source.
        var text
        if (localWasm) {
            console.assert(localWasm.js, `Local version ${localWasm.version} is missing js file!`)
            text = localWasm.js
        } else {
            console.assert(remoteWasm, 'No local version or remote version was found!')
            text =
                // DEBUG fetch local
                // await fetch('/mysterious-castle.js').then((response) => response.text())
                await fetchJs(remoteWasm)
        }
        console.assert(text, 'Must have js source file!')

        if (localWasm && localWasm.wasm) {
            console.info('Loading local WASM...')
            // We already have the local wasm, no need to show progress.
            // But put a bit of delay for compilation...
            setTimeout(() => {
                setLoadingProgress(1.0)
                setShowLoading(false)
                setShowPreamble(true)
            }, 1000)
        } else {
            console.info('Loading remote WASM...')
            const onProgress = (e) => {
                setLoadingProgress(e.detail.readBytes / e.detail.totalBytes)
            }
            var onFinished = null
            onFinished = (e) => {
                setLoadingProgress(1.0)
                setShowLoading(false)
                setShowPreamble(true)
                window.removeEventListener('fetch-wasm-progress', onProgress)
                window.removeEventListener('fetch-wasm-finished', onFinished)
            }
            window.addEventListener('fetch-wasm-progress', onProgress)
            window.addEventListener('fetch-wasm-finished', onFinished)
        }

        const script = document.createElement('script')
        script.text = text
        script.async = true
        script.onload = async () => {
            console.debug('Finished loading WASM. Booting up game...')
        }

        // Add the script to the end of the body:
        document.querySelector('#game-container').appendChild(script)
    }

    // Get available versions from the remote:
    const getVersions = async () => {
        const versions = await fetch(process.env.PUBLIC_URL + '/versions.json')
        const json = await versions.json()
        console.info('Game versions:', json.versions)
        return json.versions
    }

    // Load the local WASM and check if it's up to date.
    // Offer the user an update if it's not.
    const loadWasm = async () => {
        const versions = await getVersions()
        const localWasm = await loadLocalVersion(versions)

        // DEBUG: Load local version.
        // if (true) {
        if (!localWasm) {
            // No local wasm, download one.
            const latestVersion = versions[0]
            console.log('No local WASM found, downloading version ' + latestVersion.version)
            await injectGame(null, latestVersion)
        } else if (!localWasm.wasm) {
            // We have an old version, ask the user if they want to update.
            setUpdateVersion(localWasm)
        } else {
            // We have the latest version
            console.log('Found local WASM version ' + localWasm.version + ', booting up...')
            await injectGame(localWasm)
        }
    }

    const preRun = [
        () => {
            console.debug('Initializing FS root folders...')
            FS.mkdir('/game')
            FS.mount(IDBFS, {}, '/game')
            FS.mkdir('/world')
            FS.mount(IDBFS, {}, '/world')
            FS.syncfs(true, function (err) {
                if (err) {
                    console.error('Error syncing FS:', err)
                } else {
                    console.log('FS root folders synced.')
                    setFilesystemInitialized(true)
                    window.filesystemSynced = true
                }
            })
        },
    ]

    // Construct base module.
    useEffect(() => {
        window.Module = {
            canvas: document.getElementById('canvas'),
            instantiateWasm,
            preRun,
            onRuntimeInitialized: () => {
                console.log('Runtime initialized.')
                setRuntimeInitialized(true)
            },
        }
    }, [])

    useEffect(() => {
        if (runtimeInitialized && filesystemInitialized) {
            // TODO: Tell game that we have finished waiting for the filesystem and runtime to initialize.
            // ...
        }
    }, [runtimeInitialized, filesystemInitialized])

    // If the user has selected an update.
    useEffect(() => {
        ;(async () => {
            console.debug('updateAction = ', updateAction)

            const remoteVersions = await getVersions()
            if (updateAction && updateAction !== 'continue') {
                // Delete the local wasm and kick-off download of new version.
                deleteLocalVersion(updateAction)
                injectGame(null, remoteVersions[0])
            } else if (updateAction === 'continue') {
                // Read from the DB to get local version and inject the game.
                const localWasm = await loadLocalVersion()
                if (!localWasm.wasm) {
                    toast({
                        id: 'errorLocalWasm',
                        title: 'Error loading saved game version',
                        description: 'We will attempt to download the latest version.',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    })
                    injectGame(null, remoteVersions[0])
                } else {
                    injectGame(localWasm)
                }
            }
        })()
    }, [updateAction])

    // If we have requested a start to the game:
    // THIS KICKS OFF THE ENTIRE DOWNLOAD-COMPILE-RUN PROCESS:
    useEffect(() => {
        if (shouldStart) {
            if (!isLoaded) {
                console.debug('Loading WASM...')
                loadWasm()
            } else {
                window.Module.resumeMainLoop()
            }
        }
        const watchResize = () => {
            setShowZoomInfo(window.devicePixelRatio <= 1)
        }
        window.addEventListener('resize', watchResize)
        return () => {
            if (isLoaded) {
                window.Module.pauseMainLoop()
            }
            window.removeEventListener('resize', watchResize)
        }
    }, [shouldStart])

    // Styles:
    const containerPadding = 10
    const navbarHeight = 200

    return (
        <div
            id='game-container'
            style={{
                backgroundColor: '#0f0f0f',
                margin: 0,
                padding: containerPadding,
            }}
        >
            {/* TODO: Maintain LOGO from home page and animate out:
            <Slide in={true} direction='top'>
                <Center mt={50}>
                    <Image
                        src='/Logo.png'
                        alt='logo'
                        style={{
                            imageRendering: 'high-quality',
                            transition: 'all linear .50s',
                            height: '300px',
                        }}
                        opacity={0.9}
                    />
                </Center>
            </Slide> */}
            <Center>
                {/* Background overlay: */}
                {(showPreamble || showLoading) && (
                    <Box
                        w={1024}
                        h={768}
                        bg={'black'}
                        opacity={showLoading ? '100%' : '85%'}
                        style={{
                            pointerEvents: 'none',
                            position: 'absolute',
                            top: containerPadding + navbarHeight,
                            zIndex: 3,
                        }}
                    />
                )}

                {/* Loading spinner: */}
                <div
                    style={{
                        zIndex: 5,
                        position: 'absolute',
                        top: containerPadding + navbarHeight + 768 / 2 - 10,
                        pointerEvents: 'none',
                    }}
                >
                    <Fade in={showLoading}>
                        <Center>
                            <VStack>
                                <Text color='white' fontSize={20} mb={10}>
                                    <i>Loading...</i>
                                </Text>
                                <Progress colorScheme='green' hasStripe value={loadingProgress * 100.0} w={'400px'} />
                            </VStack>
                        </Center>
                    </Fade>
                </div>

                {/* Preamble contents: */}
                <Box
                    w={1024}
                    h={768}
                    style={{
                        position: 'absolute',
                        top: 60,
                        margin: 0,
                        padding: 200,
                        color: 'white',
                        zIndex: 4,
                        ...(!showPreamble ? { pointerEvents: 'none' } : {}),
                    }}
                >
                    <Fade in={showPreamble} transition={{ enter: { duration: 2.0 } }}>
                        <VStack width={'100%'}>
                            <Text color='white' w='100%' fontSize='3xl'>
                                <u>
                                    Welcome to <b>Mysterious Castle</b>
                                </u>
                            </Text>
                            <Text color='white' w='100%'>
                                This is a <b>BETA</b> quality release, and as such there are a few things should be
                                aware of:
                            </Text>
                            <List spacing={3} mt={3}>
                                <ListItem>
                                    <ListIcon as={InfoIcon} />
                                    Please report any bugs you encounter using the{' '}
                                    <b>
                                        <i className='ri-star-line ri-sm' />
                                    </b>{' '}
                                    sidebar menu item.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={InfoIcon} />
                                    This web port has been developed for the <b>Chrome</b> browser. Other browsers may
                                    work, but are not officially supported.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={InfoIcon} />
                                    Chrome may <span style={{ color: '#ef1234' }}>delete your save data</span> when it's
                                    updated, or after some system defined time-out. To preserve your progress{' '}
                                    <b>install the game</b> by pressing the install button in Chrome's address bar:
                                    <Center>
                                        <VStack>
                                            <img
                                                alt='install-button'
                                                src='/install.png'
                                                style={{
                                                    padding: '10px',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                            {/* <span>
                                                <i style={{ fontSize: 13 }}>
                                                    (Report any problems in the <i className='ri-star-line ri-sm' />{' '}
                                                    sidebar)
                                                </i>
                                            </span> */}
                                        </VStack>
                                    </Center>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={InfoIcon} />
                                    Audio may glitch out.{' '}
                                    <b>
                                        <i>Please be cautious when using headphones.</i>
                                    </b>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={InfoIcon} />
                                    Use your web browsers zoom (
                                    <i>
                                        <u>command +/-</u>
                                    </i>
                                    ) to scale the game.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={InfoIcon} />
                                    While I will make every effort to prevent breaking save files, I cannot guarantee
                                    that they will always be compatible.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={InfoIcon} />
                                    This game was designed from the ground up for mobile devices, so the best experience
                                    is to{' '}
                                    <Link
                                        href='https://apps.apple.com/us/app/mysterious-castle/id465647954'
                                        isExternal
                                        color='green.300'
                                    >
                                        get the iOS app <ExternalLinkIcon mx='2px' />
                                    </Link>{' '}
                                    <i>or</i>{' '}
                                    <Link
                                        href='https://play.google.com/store/apps/details?id=com.eclectocrat.mysterious_castle&hl=en_US'
                                        isExternal
                                        color='green.300'
                                    >
                                        get the Android app <ExternalLinkIcon mx='2px' />
                                    </Link>
                                    .
                                </ListItem>
                            </List>
                            <Button
                                mt={5}
                                onClick={() => {
                                    setShowPreamble(false)
                                    if (onGameStart) {
                                        onGameStart()
                                    }
                                }}
                            >
                                I understand, let's play!
                            </Button>
                        </VStack>
                    </Fade>
                </Box>

                {/* Main game canvas: */}
                <canvas
                    id='canvas'
                    w={1024}
                    h={768}
                    style={{
                        backgroundColor: 'black',
                        margin: 'auto',
                        display: 'block',
                        zIndex: 2,
                        // TODO: These are problematic but sometimes necessary.
                        // Have to figure out why when these are missing, _rarely_ the width and height are ZERO.
                        // width: '1024px',
                        // height: '768px',
                    }}
                    onContextMenu={(event) => event.preventDefault()}
                    tabIndex='-1'
                />
            </Center>
            <Center>
                <ScaleFade in={showZoomInfo} transition={{ exit: { duration: 2.0 } }}>
                    <Text m={1} color='#555555' fontSize={'small'}>
                        Use your web browsers zoom (
                        <i>
                            <u>command +/-</u>
                        </i>
                        ) to scale the game.
                    </Text>
                </ScaleFade>
            </Center>

            {/* Right control bar: */}
            <Fade in={!isOpen} transition={{ enter: { duration: 1.5 } }}>
                <Toolbar
                    position='right'
                    showFullscreen={true}
                    onFullscreen={showLoading || showPreamble ? null : () => Module.requestFullscreen(false, true)}
                    onHelp={() => {
                        openSidebar('help')
                    }}
                    onBug={() => {
                        openSidebar('bug')
                    }}
                    onDonate={() => {
                        openSidebar('donate')
                    }}
                    onSocial={() => {
                        openSidebar('social')
                    }}
                    onWallet={() => {}}
                />
            </Fade>

            {/* Drawer with sidebar contents: */}
            <InfoDrawer
                initialSection={infoState}
                isOpen={isOpen}
                onClose={() => {
                    window.Module.ignoreKeyboardEvents = false
                    onClose()
                }}
            />

            {/* Modal for version upgrade */}
            <Modal size={'xl'} isOpen={updateVersion != null} isCentered={true}>
                <ModalOverlay />
                <ModalContent bg={'#222222'}>
                    <ModalHeader textColor={'white'}>New Version Available</ModalHeader>
                    <ModalBody textColor={'white'}>
                        There is a new version of the game available. Would you like to update?
                        <ReleaseNotes version={updateVersion} />
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme='blue'
                            mr={3}
                            onClick={() => {
                                const version = updateVersion.localVersion.version
                                setUpdateAction(version)
                                setUpdateVersion(null)
                            }}
                        >
                            Update
                        </Button>
                        <Button
                            colorScheme='red'
                            onClick={() => {
                                setUpdateAction('continue')
                                setUpdateVersion(null)
                            }}
                        >
                            Don't Update
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default Game
