import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Fade,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    ListItem,
    OrderedList,
    Text,
    UnorderedList,
} from '@chakra-ui/react'
import { Glossary } from './Glossary'
import Analytics from './Analytics'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import 'remixicon/fonts/remixicon.css'

function HelpInfo({ termFilter, setTermFilter, setIsHelpScrolled }) {
    const sections = [
        { title: 'Running the game in your browser', contents: 'running-game' },
        { title: 'How to choose a starting party', contents: 'starting-party' },
        { title: 'How to play the game', contents: 'how-to-play' },
        { title: 'Help! I keep dying!', contents: 'its-too-hard' },
    ]

    const [renderGlossary, setRenderGlossary] = useState(false)

    // Glossary is too big to be present while the drawer animates out.
    useEffect(() => {
        setTimeout(() => {
            setRenderGlossary(true)
        }, 1000)
    }, [])

    const ulStyle = { paddingLeft: 50, marginTop: 10, marginBottom: 10 }

    const RunningTheGame = () => (
        <>
            <br />
            <p>
                Mysterious Castle for the web has been ported to run in the Chrome web browser. Other browsers may work,
                but have not been tested, and I won't attempt to fix bugs presenting in non-Chrome browsers.
            </p>
            <br />
            <p>In order to scale the game display to your screen, use the zoom feature of your browser.</p>
            <br />
            <p>
                There is no in-game way to return to the main menu, if you want to quit or start a new game, just reload
                the webpage.
            </p>
        </>
    )

    const ChooseAParty = () => (
        <>
            <br />
            <p>
                The game is designed for a party of 4 characters. You can choose any combination of the 16 races but you
                will find the game balanced for a party of two fighters, one rogue, and one mage. In party select screen
                you will find that each horizontal row of characters is a balanced party.
            </p>
            <br />
            <p>
                You can choose a party of fewer than 4 characters, but you will find the challenge increases
                dramatically as you have fewer and fewer characters. I have beaten the game with a single character, but
                this is only recommended for advanced players who've already beaten the game with a full party.
            </p>
            <br />
            <p>
                In the party select screen you'll find a button at the bottom right of the screen to select your party{' '}
                <i>bonus</i>. This bonus will be applied as improved starting equipment and spells for your party. It's
                recommended that you start with a bonus of <b>+1</b> for your first playthrough, although you can choose
                a higher bonus if you're having a hard time surviving early battles.
            </p>
        </>
    )

    const HowToPlay = () => (
        <>
            <br />
            <p>
                Mysterious Castle is designed for mobile devices, so the interface is limited to tapping (or clicking in
                the browser) to move and bring up contextual menus in the game. On mobile devices additional screens are
                presented by <i>swiping</i> <b>left, right, up, or down</b>. In this web port you can{' '}
                <i>press the arrow keys</i> to simulate swiping screens in and out:
            </p>
            <ul style={ulStyle}>
                <li>
                    <i className='ri-arrow-down-line'></i> Show game settings screen.
                </li>
                <li>
                    <i className='ri-arrow-left-line'></i> Show character status and inventory screen.
                </li>
                <li>
                    <i className='ri-arrow-right-line'></i> Show spellbook screen.
                </li>
                <li>
                    <i className='ri-arrow-up-line'></i> Show quick-actions toolbar.
                </li>
            </ul>
            <p>There are also a few convenience shortcut keys in the web version:</p>
            <ul style={ulStyle}>
                <li>
                    <b>
                        <Text as='kbd'>SPACE</Text> -
                    </b>{' '}
                    Choose the <b>wait</b> action.
                </li>
                <li>
                    <b>
                        <Text as='kbd'>TAB</Text> -
                    </b>{' '}
                    Switch party leader to next character.
                </li>
                <li>
                    <b>
                        <Text as='kbd'>SHIFT</Text> -
                    </b>{' '}
                    Show prepared spell view.
                </li>
                <li>
                    <b>
                        <Text as='kbd'>S</Text> -
                    </b>{' '}
                    Quicksave the game (when not in combat).
                </li>
            </ul>
            <br />
            <p>There is also a HUD displaying three icons in the top-right, bottom-right, and bottom-left corners:</p>
            <ul style={ulStyle}>
                <li>
                    <span>
                        <img src='/helpinfo-gear.png' alt='settings' style={{ float: 'left', marginRight: 10 }} />
                    </span>
                    {'  '}
                    Present the game settings screen.
                </li>
                <br />
                <li>
                    <span>
                        <img src='/helpinfo-save.png' alt='settings' style={{ float: 'left', marginRight: 10 }} />
                    </span>
                    {'  '}
                    Quicksave the game, if you are not in combat.
                </li>
                <br />
                <li>
                    <span id='help-mode'>
                        <img src='/helpinfo-help.png' alt='settings' style={{ float: 'left', marginRight: 10 }} />
                    </span>
                    {'  '}
                    Enter <i>help mode</i>.
                </li>
            </ul>
            <br />
            <p>
                <i>Help mode</i> is the interface to an in-game info system that can explain anything you click on. When
                you enter <i>help mode</i> your regular actions are disabled and instead you can see info describing
                anything you click on. You can use help mode to get info about objects, how actions work, and even info
                about enemy races you encounter. To exit <i>help mode</i> you can click on the help HUD button again, or
                click on the floating toast message that appears at the top of the screen. Help mode also works in your
                inventory and spellbook screens.
            </p>
            <br />
            <p>
                A mini-map is displayed in the top left corner of the screen. This map shows the area immediately
                surrounding the active character. To see more of the world, enter the inventory screen and find the{' '}
                <b>world map</b> item that one of your characters carries. Click on it and <b>read</b> it to see the
                entire world map.
            </p>
        </>
    )

    const ItsTooHard = () => (
        <>
            <br />
            <p>
                Mysterious Castle is a roguelike, which means it's designed to be challenging. If you're having trouble
                surviving, here are some tips:
            </p>
            <ul style={ulStyle}>
                <li>
                    <b>Important:</b> Once in a while, due to the dynamic nature of the world generation code, you may
                    find your starting location surrounded by enemies that are way too powerful to defeat. If this
                    happens, just regenerate the world and try again. You'll know this is happening if you immediately
                    find yourself in the midst of ogres, giants, vampires, or elementals.
                </li>
                <br />
                <li>
                    Select a balanced starting party. If you frequently run low on hitpoints, try starting with a{' '}
                    <b>troll</b>, as it's regenerate ability will have him fully refreshed before each combat.
                </li>
                <br />
                <li>Start with a higher party bonus, this will give you better starting equipment and spells.</li>
                <br />
                <li>
                    Your characters start with <i>random</i> equipment. Take a look at your items and characters
                    abilities and trade items to maximize fighting power.
                </li>
                <br />
                <li>
                    Don't be afraid to run away. Sometimes running is the best strategy to draw out difficult enemies
                    into more tactically advantageous positions.
                </li>
                <br />
                <li>
                    Find another route. Due to the open nature of the world generation, there are often multiple paths
                    to take. If you're having trouble with a particular area, try exploring other areas first. If you
                    really want to get to a particular area but can't handle the combat, try sneaking past your enemies.
                </li>
                <br />
                <li>
                    Send your rogue to scout and collect resources. If you are low on <b>fruit</b> or <b>mushrooms</b>,
                    send your rogue in <b>sneak</b> mode out to find additional resources. You can also use your rogue
                    to scout out what enemies are ahead and prepare suitable spells and weapons.
                </li>
            </ul>
        </>
    )

    const linkColor = '#718096'

    useEffect(() => {
        if (termFilter.length > 2) {
            const el = document.querySelector('#glossary')
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' })
            }
            Analytics.event('help', 'filter-glossary', termFilter)
        }
    }, [termFilter])

    return (
        <div
            // TODO: This isn't working, the scroll event isn't firing:
            onScroll={(e) => {
                setIsHelpScrolled(true)
            }}
            id='help-scroll-container'
            style={{ color: 'white', overflowY: 'scroll', padding: 5, paddingTop: 0 }}
        >
            <UnorderedList id='contents-index'>
                {sections.map((s, i) => (
                    <ListItem key={i}>
                        <Button
                            variant='link'
                            onClick={() => {
                                document.querySelector('#' + s.contents).scrollIntoView({ behavior: 'smooth' })
                                Analytics.event('help', 'jump-to-section', s.contents)
                            }}
                        >
                            {s.title}
                        </Button>
                    </ListItem>
                ))}
                <ListItem key={'players-guide'}>
                    <Button
                        variant='link'
                        onClick={() => {
                            Analytics.event('help', 'go-to-players-guide')
                            window.open('/players-guide', '_blank')
                        }}
                    >
                        {"Read the Player's Guide"}
                        <ExternalLinkIcon mx='2px' />
                    </Button>
                </ListItem>
            </UnorderedList>
            <br />
            <HStack>
                <Text id='glossary' fontSize='xl' color={linkColor}>
                    <u>Glossary of terms</u>
                </Text>
                <Fade in={!renderGlossary}>
                    <CircularProgress isIndeterminate color='green.300' size={'30px'} />
                </Fade>
            </HStack>
            <br />
            <Fade in={renderGlossary}>
                {renderGlossary && (
                    <Glossary filter={termFilter.length > 2 ? termFilter : null} setFilter={setTermFilter} />
                )}
            </Fade>
            {/* <Collapse in={!termFilter}> */}
            <br />
            <Text fontSize='xl' id='running-game' color={linkColor}>
                <u>Running the game in your browser</u>
            </Text>
            <RunningTheGame />
            <br />
            <Text fontSize='xl' id='starting-party' color={linkColor}>
                <u>Choosing a starting party</u>
            </Text>
            <ChooseAParty />
            <br />
            <Text fontSize='xl' id='how-to-play' color={linkColor}>
                <u>How to play</u>
            </Text>
            <HowToPlay />
            <br />
            <Text fontSize='xl' id='its-too-hard' color={linkColor}>
                <u>Help! I keep dying!</u>
            </Text>
            <ItsTooHard />
            <br />
            {/* </Collapse> */}
            {/* Bottom padding: */}
            <Box style={{ height: 'calc(100vh)' }} />
        </div>
    )
}

export { HelpInfo }
