/* eslint no-undef: "warn" */
import React, { useEffect, useState } from 'react'

// Main Chakra UI components:
import {
    Box,
    Button,
    Center,
    HStack as ChakraHStack,
    Image,
    Link,
    List,
    ListItem,
    OrderedList,
    Text,
    UnorderedList,
    VStack,
} from '@chakra-ui/react'

// External libs:
import { Link as RouteLink, useLocation } from 'react-router-dom'

import 'remixicon/fonts/remixicon.css'

// Local components and data:
import useMatchMedia from './MatchMedia'
import { Glossary } from './Glossary'
import Analytics from './Analytics'
import {
    actionColors,
    cursorColors,
    ActionBar,
    HStack,
    NotePane,
    SectionHeader,
    SubsectionHeader,
    RadialImage,
    RectImage,
    style,
} from './PlayersGuideUtil'

const PlayersGuideIntro = () => {
    return (
        <VStack>
            <HStack maxWidth={style.maxBodyWidth}>
                <RadialImage src='darkforest01.webp' />
                <Text maxWidth={style.defaultColumnWidth}>
                    <i>
                        Deep in a dark forest an evil necromancer has unleashed hordes of monsters from the depths of
                        hell. Now you are alone and beseiged, no-one will come to your aid. You must fight your way
                        through the forest and defeat the necromancer before he can complete his dark ritual and unleash
                        the ultimate evil upon the world.
                        <br />
                        <br />
                        Your party of rookie adventurers has to stand against horrors untold, but you aren't up to the
                        challenge... yet. Use your combat prowess, magical talents, and stealth to overcome foes, and
                        loot the dungeons that lie beneath the forest. With the help of powerful weapons, ancient
                        spells, and books of lore, you can become the heroes that you'll need to be to defeat darkness.
                    </i>
                </Text>
            </HStack>
            <HStack mt={10} maxWidth={style.maxBodyWidth}>
                <Text maxWidth={style.defaultColumnWidth}>
                    Mysterious Castle is a <b>tactics-roguelike</b>, a game that combines the tactical depth of
                    squad-based tactics games with the replayability and unpredictability of roguelikes. You control a
                    party of up to 4 characters as they explore a forest at nightfall. There are enemies at every turn
                    and one wrong move can lead to your party's demise.
                    <br />
                    <br />
                    This game inherits the difficulty of classic roguelikes, so you'll likely die, and die again, and
                    keep dying until you learn the game's mechanics and how to use them to your advantage. However,{' '}
                    <i>unlike</i> classic roguelikes, permadeath is not a core mechanic. Instead, you will face hordes
                    of seemingly undefeatable monsters over and over again, until you find the right strategy to destroy
                    them.
                </Text>
                <RadialImage src='darkforest02.webp' />
            </HStack>
        </VStack>
    )
}

export default PlayersGuideIntro
