import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

console.history = []
console.log_base = console.log
console.log = function (...args) {
    console.log_base(...arguments)
    console.history.push(args)
    if (console.history.length > 100) {
        console.history.shift()
    }
}
console.error_base = console.error
console.error = function (...args) {
    console.error_base(...arguments)
    console.history.push(args)
    if (console.history.length > 100) {
        console.history.shift()
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
