const reviews = [
    {
        title: 'Loved it!',
        content: 'One of the best roguelike games I ever played! Each new map has hours of fun!',
        stars: 5,
        by: 'hbreders',
    },
    {
        title: 'Brilliant atmosphere',
        content: 'More games like this please... So cool!',
        stars: 5,
        by: 'Elven-ring',
    },
    {
        title: 'Incredible',
        content: 'Absolutely incredible.',
        stars: 5,
        by: 'MegaKuph',
    },
    {
        title: 'Splendid!',
        content:
            "This pixelated RPG game is absolutely one of the best I've ever played. It's randomly generated maps never let you down nor do the cute little NPCs/characters.",
        stars: 5,
        by: 'Bobby smith jr the third',
    },
    {
        title: 'Very Good!',
        content: 'An excellent rougelike which has clearly had some love and attention lavished on it...',
        stars: 5,
        by: 'Goonerman158',
    },
    {
        title: 'Great game',
        content:
            'Extreme amount of complexity and depth for being such a simple premise. One of the better games i have played on ipad in terms of being casual and strategic.',
        stars: 5,
        by: 'Felixblue123',
    },
    {
        title: 'Pretty awesome!',
        content:
            "I love isometric tactical RPG's! But what I wasn't expecting (but a pleasant surprise) was the rogue like play style. Man this game scratches both of those itches soooo good!",
        stars: 5,
        by: 'tB ISHER',
    },
    {
        title: 'Fantastic',
        content: 'Excellent rogue-like with party of 4. Scratches old school RPG itch like a fork under a cast ;)',
        stars: 5,
        by: 'Therealsloops',
    },
]

export default reviews
