import React, { useState } from 'react'
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    Flex,
    FormControl,
    FormLabel,
    Link,
    ListItem,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderTrack,
    SliderThumb,
    Spacer,
    Stack,
    Text,
    Textarea,
    UnorderedList,
    VStack,
    HStack,
    useToast,
} from '@chakra-ui/react'

import { getUserId, loadLocalVersion } from './IDB'

import { Field, Form, Formik } from 'formik'
import { ExternalLinkIcon } from '@chakra-ui/icons'

function ReportABugInfo() {
    const toast = useToast()
    const [description, setDescription] = useState('')
    const [outcomeNothing, setOutcomeNothing] = useState(false)
    const [outcomeVisualGlitch, setOutcomeVisualGlitch] = useState(false)
    const [outcomeAction, setOutcomeAction] = useState(false)
    const [outcomeCrash, setOutcomeCrash] = useState(false)
    const [outcomeLoad, setOutcomeLoad] = useState(false)
    const [getBackToMe, setGetBackToMe] = useState(false)
    const [sending, setSending] = useState(false)
    const [rating, setRating] = useState(0)
    const [comments, setComments] = useState('')
    const [sliderValue, setSliderValue] = useState(50)
    const [didChangeSlider, setDidChangeSlider] = useState(false)
    const [didSubmitFeedback, setDidSubmitFeedback] = useState(false)

    const linkColor = '#718096'
    const MyListItem = ({ children }) => <ListItem style={{ marginTop: 10, marginBottom: 10 }}>{children}</ListItem>

    const submitReport = async () => {
        const userId = await getUserId()
        // The versions dummy object is to force us to fetch the current version without loading the WASM file.
        const localVersion = await loadLocalVersion([{ version: 'not-a-version-string' }])
        const stack_trace = console.history
            .reverse()
            .flatMap((o) => String(o))
            .join('\n')
        const report = {
            description: description,
            user_id: userId,
            game_version: localVersion.version || 'no-version',
            outcomes: {
                nothing: outcomeNothing,
                visual_glitches: outcomeVisualGlitch,
                broken_action: outcomeAction,
                crash: outcomeCrash,
                load: outcomeLoad,
            },
            // TODO
            // feedback: getBackToMe,
            stack_trace: stack_trace.length > 500 ? stack_trace.substring(stack_trace.length - 500) : stack_trace,
        }

        const t = {
            description: 'description',
            user_id: 'test',
            game_version: '1.2.3',
            outcome: {
                nothing: true,
                visual_glitches: false,
                broken_action: true,
                crash: false,
                broken_savefile: true,
            },
            stack_trace: ['stack', 'trace'],
        }

        const reportError = () => {
            if (!toast.isActive('bug')) {
                toast({
                    id: 'bug',
                    title: 'Error submitting bug report',
                    description: 'Please try again later.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        }

        fetch(
            'https://faas-ams3-2a2df116.doserverless.co/api/v1/web/fn-7c9ff411-47d1-49a3-abc7-b00d1c511943/functions/bug_report',
            {
                method: 'POST',
                body: JSON.stringify(report),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.message === 'ok') {
                    toast({
                        id: 'rating',
                        title: 'Thanks for your bug report',
                        description: 'Your feedback helps make Mysterious Castle better.',
                        status: 'warning',
                        duration: 3000,
                        isClosable: true,
                    })

                    setDescription('')
                    setOutcomeNothing(false)
                    setOutcomeVisualGlitch(false)
                    setOutcomeAction(false)
                    setOutcomeCrash(false)
                    setOutcomeLoad(false)
                } else {
                    reportError()
                }
            })
            .catch((err) => {
                console.log('Error: ', err)
                reportError()
            })
    }

    const submitFeedback = async () => {
        const userId = await getUserId()
        const feedback = {
            user_id: userId,
            rating: rating,
            difficulty: didChangeSlider ? sliderValue : 0,
            notes: comments,
        }

        setDidSubmitFeedback(true)

        const reportError = () => {
            if (!toast.isActive('rating')) {
                toast({
                    id: 'rating',
                    title: 'Error submitting feedback',
                    description: 'Please try again later.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        }

        fetch(
            'https://faas-ams3-2a2df116.doserverless.co/api/v1/web/fn-7c9ff411-47d1-49a3-abc7-b00d1c511943/functions/feedback',
            {
                method: 'POST',
                body: JSON.stringify(feedback),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.message === 'ok') {
                    toast({
                        id: 'rating',
                        title: 'Thanks for your feedback!',
                        description: 'Your feedback helps make Mysterious Castle better.',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                } else {
                    reportError()
                }
            })
            .catch((error) => {
                console.error('Error:', error)
                reportError()
            })
    }

    // using the <i className='ri-star-fill'></i> and <i className='ri-star-line'></i> icons, make
    // a control to select a star rating from one to 5
    const StarRating = ({ rating, setRating }) => {
        return (
            <Stack direction='row' spacing={1}>
                <Button
                    variant='ghost'
                    p={0.5}
                    onClick={() => setRating(1)}
                    color={rating >= 1 ? 'yellow.300' : 'gray.300'}
                >
                    <i className='ri-star-fill ri-xl'></i>
                </Button>
                <Button
                    variant='ghost'
                    p={0.5}
                    onClick={() => setRating(2)}
                    color={rating >= 2 ? 'yellow.300' : 'gray.300'}
                >
                    <i className='ri-star-fill ri-xl'></i>
                </Button>
                <Button
                    variant='ghost'
                    p={0.5}
                    onClick={() => setRating(3)}
                    color={rating >= 3 ? 'yellow.300' : 'gray.300'}
                >
                    <i className='ri-star-fill ri-xl'></i>
                </Button>
                <Button
                    variant='ghost'
                    p={0.5}
                    onClick={() => setRating(4)}
                    color={rating >= 4 ? 'yellow.300' : 'gray.300'}
                >
                    <i className='ri-star-fill ri-xl'></i>
                </Button>
                <Button
                    variant='ghost'
                    p={0.5}
                    onClick={() => setRating(5)}
                    color={rating >= 5 ? 'yellow.300' : 'gray.300'}
                >
                    <i className='ri-star-fill ri-xl'></i>
                </Button>
            </Stack>
        )
    }

    return (
        <Box style={{ color: 'white', overflowY: 'scroll', padding: 5, paddingTop: 0, margin: 0 }}>
            <Text fontSize='xl' color={linkColor}>
                <u>Rate Mysterious Castle</u>
            </Text>
            <br />
            <StarRating
                rating={rating}
                setRating={(num) => {
                    setDidSubmitFeedback(false)
                    setRating(num)
                }}
            />
            <Collapse in={rating > 0 && !didSubmitFeedback}>
                <VStack alignItems={'start'} mt={5}>
                    <Flex width={'100%'}>
                        <Text>
                            <b>Too Easy</b>
                        </Text>
                        <Spacer />
                        <Text>
                            <b>Just Right</b>
                        </Text>
                        <Spacer />
                        <Text>
                            <b>Too Hard</b>
                        </Text>
                    </Flex>
                    <Box style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}>
                        <Slider
                            step={1}
                            min={1}
                            max={5}
                            onChange={(val) => {
                                setDidChangeSlider(true)
                                setSliderValue(val)
                            }}
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb boxSize={5} />
                        </Slider>
                    </Box>
                    <Textarea
                        mt={3}
                        maxLength='500'
                        placeholder='(Optional) Additional Comments'
                        onChange={(e) => setComments(e.target.value)}
                    ></Textarea>
                    <HStack spacing={0}>
                        <Text
                            fontSize={'xs'}
                            color={
                                comments.length > 450 ? '#ee1122' : comments.length >= 350 ? 'yellow.300' : '#999999'
                            }
                        >
                            {comments.length}
                        </Text>
                        <Text fontSize={'xs'} color={'#999999'}>
                            /500 characters
                        </Text>
                    </HStack>
                    <Button mt={5} onClick={submitFeedback}>
                        Submit
                    </Button>
                </VStack>
            </Collapse>
            <br />
            <Text>
                Please join us on{' '}
                <Link
                    href='https://discord.com/channels/1198772015079895112/1198772015541260328'
                    isExternal
                    color='green.300'
                >
                    Discord <i className='ri-discord-line'></i> <ExternalLinkIcon mx='2px' />
                </Link>{' '}
                to share your thoughts.
            </Text>
            <br />
            <Text fontSize='xl' color={linkColor}>
                <u>Known bugs and issues</u>
            </Text>
            <br />

            <p>
                Mysterious Castle is a crusty old game, and it's far from perfect. The game should run fairly well, but
                you may experience a crash. Due to the auto-save system, you'll rarely lose much progress, if any.
            </p>
            <br />
            <p>Here are some known bugs and issues: </p>

            <UnorderedList>
                <MyListItem>
                    A character may occasionally <i>disappear</i> when it shares a space with another creature. The next
                    time that character moves, they will reappear. If you are not in combat, try switching your party
                    leader and moving around a bit to make the invisible creature reappear.
                </MyListItem>
                <MyListItem>
                    Sometimes, two items overlap in a chest. This makes it hard to see at a glance what is in the chest,
                    but otherwise presents no other buggy behavior.
                </MyListItem>
                <MyListItem>
                    Due to the nature of isometric rendering, it's sometimes hard to click something that is behind an
                    obstacle like a copse of trees. Objects that are near the party leader are rendered with some
                    transparency and you can click the objects behind them, but this is not always enough. You may need
                    to be pixel precise with your clicks.
                </MyListItem>
            </UnorderedList>
            <br />
            <Text fontSize='xl' color={linkColor}>
                <u>Report a bug</u>
            </Text>
            <br />
            <Text>If you encounter any other bugs, please submit a bug report.</Text>
            <br />
            <Formik initialValues={{ description: '' }}>
                {({}) => (
                    <Form
                        onSubmit={() => {
                            console.log('Submit')
                            submitReport()
                        }}
                    >
                        <Field name='description' validate={(value) => (value ? '' : 'Required')}>
                            {({ field, form }) => (
                                <FormControl id='description' isRequired>
                                    <FormLabel>Description</FormLabel>
                                    <Textarea
                                        maxLength='500'
                                        placeholder='Describe what actions led up to the bug'
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    ></Textarea>
                                    <HStack spacing={0} mt={2}>
                                        <Text
                                            fontSize={'xs'}
                                            color={
                                                description.length > 450
                                                    ? '#ee1122'
                                                    : description.length >= 350
                                                    ? 'yellow.300'
                                                    : '#999999'
                                            }
                                        >
                                            {description.length}
                                        </Text>
                                        <Text fontSize={'xs'} color={'#999999'}>
                                            /500 characters
                                        </Text>
                                    </HStack>
                                </FormControl>
                            )}
                        </Field>
                        <br />
                        <Field name='category'>
                            {({ field, form }) => (
                                <FormControl id='category'>
                                    <FormLabel>Outcome</FormLabel>
                                    <Stack direction={['column']} spacing={[1, 1]}>
                                        <Checkbox
                                            isChecked={outcomeNothing}
                                            onChange={(e) => setOutcomeNothing(e.target.checked)}
                                        >
                                            Nothing
                                        </Checkbox>
                                        <Checkbox
                                            isChecked={outcomeVisualGlitch}
                                            onChange={(e) => setOutcomeVisualGlitch(e.target.checked)}
                                        >
                                            A visual glitch
                                        </Checkbox>
                                        <Checkbox
                                            isChecked={outcomeAction}
                                            onChange={(e) => setOutcomeAction(e.target.checked)}
                                        >
                                            {' '}
                                            An action didn't work
                                        </Checkbox>
                                        <Checkbox
                                            isChecked={outcomeCrash}
                                            onChange={(e) => setOutcomeCrash(e.target.checked)}
                                        >
                                            The game crashed
                                        </Checkbox>
                                        <Checkbox
                                            isChecked={outcomeLoad}
                                            onChange={(e) => setOutcomeLoad(e.target.checked)}
                                        >
                                            I can't load my savefile
                                        </Checkbox>
                                    </Stack>
                                </FormControl>
                            )}
                        </Field>
                        <br />
                        <Field name='feedback'>
                            {({ field, form }) => (
                                <Collapse in={/*outcomeLoad*/ false}>
                                    <FormControl id='connect'>
                                        <Text>
                                            In some limited cases, I may be able to save a broken savefile.{' '}
                                            <b>
                                                I DO NOT GUARANTEE THAT I WILL FIX ANY SAVEFILES, OR RESPOND TO ANY
                                                CRASH REPORTS
                                            </b>
                                            , but if you send me any broken games, it will help me make the game more
                                            stable and I <i>may</i> respond with a fixed savefile.
                                        </Text>
                                        <br />
                                        <Checkbox onChange={(e) => setGetBackToMe(e.target.checked)}>
                                            Yes, please get back to me if you are able
                                        </Checkbox>
                                    </FormControl>
                                    <br />
                                </Collapse>
                            )}
                        </Field>
                        <Button mt={4} isLoading={sending} type='submit'>
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
            {/* Bottom padding: */}
            <Box style={{ height: 200 }} />
        </Box>
    )
}

export { ReportABugInfo }
