/* eslint no-undef: "warn" */
import React, { useEffect, useState } from 'react'

// Main Chakra UI components:
import {
    Box,
    Button,
    Center,
    HStack as ChakraHStack,
    Image,
    Link,
    List,
    ListItem,
    OrderedList,
    Text,
    UnorderedList,
    VStack,
} from '@chakra-ui/react'

// External libs:
import { Link as RouteLink, useLocation } from 'react-router-dom'

import 'remixicon/fonts/remixicon.css'

// Local components and data:
import useMatchMedia from './MatchMedia'
import { Glossary } from './Glossary'
import Analytics from './Analytics'
import {
    actionColors,
    cursorColors,
    ActionBar,
    HStack,
    NotePane,
    SectionHeader,
    SubsectionHeader,
    RadialImage,
    RectImage,
    style,
} from './PlayersGuideUtil'

const PlayersGuideFirstSteps = () => {
    return (
        <VStack mt={style.sectionSpacing} spacing={style.defaultSpacing}>
            <Center h={'75px'}>
                <SectionHeader>Preparing for Adventure</SectionHeader>
            </Center>
            <HStack>
                <VStack width={'100%'}>
                    <Text>
                        Your party of adventurers start the game in a small home in the forest. A short dialog
                        introduces you to the world and game.
                        <br />
                        <br />
                        You can move by tapping on a location, if there is a path, the <i>leader</i> will move first and
                        the rest of the party will follow. You can tap on an object to approach it, and tap on it when
                        you're near to show it's <i>context menu</i>. You access almost every action through a{' '}
                        <i>context menu</i>, which shows a menu of actions depending on your knowledge, combat state,
                        action points, equipment, and how far you are from the object.
                    </Text>
                    <OrderedList>
                        <ListItem>
                            Tap on one of your <i>fighters</i> and tap on their race to make them your party{' '}
                            <i>leader</i>.
                        </ListItem>
                        <ListItem>
                            Tap on the floor and move around the room to see your party's <i>follow order</i>. You can
                            then tap on characters and use the <i>back</i> action to move them one position back in the
                            follow order. In general you'll want to keep frailer characters like mages and rogues near
                            the end of the follow order, but you can experiment with different orders to suit various
                            tactics.
                        </ListItem>
                        <ListItem>
                            Open your inventory by <i>swiping from right to left</i> or by pressing the left arrow key{' '}
                            <i className='ri-arrow-left-line'></i>.
                        </ListItem>
                        <ListItem>
                            Tap on the character selector until you find the character holding the <i>torch</i>. If it's
                            not your party leader, tap on the torch and select <i>move/give</i> and then tap on your
                            party leader in the inventory screen to give them the torch.
                        </ListItem>
                        <ListItem>
                            Return to your party leader and tap on the torch, and then tap on <i>equip</i>, to equip it.
                        </ListItem>
                        <ListItem>
                            <i>Swipe from left to right</i> or press the right arrow key{' '}
                            <i className='ri-arrow-right-line'></i> to dismiss the inventory.
                        </ListItem>
                    </OrderedList>
                </VStack>
                <VStack style={{ maxWidth: '50%' }}>
                    <NotePane>
                        <Text>
                            <i class='ri-information-line ri-xl'></i> Mysterious Castle is designed for touchscreens, so
                            everything can be accomplished with tapping or clicking. There are a few keyboard shortcuts
                            in the web version detailed below.
                        </Text>
                    </NotePane>
                    <RectImage
                        src='/playersguide/contextmenuintro.png'
                        alt='contextmenuintro'
                        heading={'A context menu you might see on your starting mage'}
                    />
                </VStack>
            </HStack>
            <HStack>
                <NotePane>
                    <HStack>
                        <Text style={{ maxWidth: '75%' }}>
                            <i class='ri-information-line ri-xl'></i> In order to navigate tight spaces, you will often
                            have to move through passable objects. To do so, tap on the object you want to move over and
                            then tap on the objects name. If you are next to the object you'll move over it, if you are
                            far away, you'll approach the object.
                            <br />
                            <br />
                            You'll need to step 'on' door frames and furninture to navigate through tight dungeons, and
                            'on' trees to find the many hidden caves and groves in the overworld.
                        </Text>
                        <RectImage src='/playersguide/walk-over.gif' alt='stepover' />
                    </HStack>
                </NotePane>
            </HStack>
            <HStack spacing={style.defaultSpacing}>
                <VStack>
                    <NotePane>
                        <Text>
                            <i class='ri-information-line ri-xl'></i> You can tap and drag to either pan the camera, or
                            select different paths quickly, depending in your game options.
                            <br />
                            <br />
                            <i class='ri-information-line ri-xl'></i> On <i>iOS and Android only</i>, you can also{' '}
                            <i>pinch-zoom</i> to help you select spaces and objects more accurately. You can also{' '}
                            <i>two-finger drag</i> to move the camera, and <i>two-finger tap</i> to recenter the camera.
                        </Text>
                    </NotePane>
                </VStack>
                <Text maxWidth={'50%'}>
                    You are now ready to explore the forest. Approach, <i>open</i>, and pass through doors until you
                    find yourself outside your home. It's dusk, the forest is dark, and growing darker. You'll have a a
                    few minutes of light before you are engulfed in pitch darkness.
                    <br />
                    <br />
                    Explore the area around you. When you approach objects like trees, you'll see they become
                    semi-transparent. You can tap through these objects to reach the spaces occluded by them.{' '}
                    <b>
                        As you move around you'll find fruit bushes, fruit trees, and mushrooms. You automatically
                        collect them when you move next to them.
                    </b>{' '}
                    You can find the <i>fruit</i> you've collected in your <i>inventory</i>{' '}
                    <i className='ri-arrow-left-line'></i>, and the mushrooms you've collected are found in your{' '}
                    <i>spellbook</i> <i className='ri-arrow-right-line'></i>.
                </Text>
            </HStack>
            <HStack spacing={style.defaultSpacing}>
                <RectImage
                    src='/playersguide/minimap.png'
                    alt='minimap'
                    width={'600px'}
                    heading={'Notice anything strange about the edges?'}
                />
                <VStack style={{ maxWidth: '50%' }}>
                    <Text>
                        After you've explored around your home, you'll see your minimap getting fleshed out in the
                        top-left corner of the screen. Different environments will draw in different colors, and
                        landmarks will be marked. This is a very important tool for navigating the forest, and essential
                        for finding hidden passages to secret groves and caves.
                        <br />
                        <br />
                        You can see the full map by going to your <i>inventory</i>{' '}
                        <i className='ri-arrow-left-line'></i>, finding the character that holds the <i>world map</i>,
                        and <i>read</i>ing it. It will be mostly empty at first, but will fill up as you explore. Your
                        location always flashes green, and other points of interest will flash in different colors.
                    </Text>
                    <NotePane>
                        <Text>
                            <i class='ri-information-line ri-xl'></i> You carry a map of the over-world, but there are
                            many entrances to caverns and dungeons beneath the forest. Your minimap will show the
                            dungeons as you explore them, but you won't see them on your <i>world map</i>.
                        </Text>
                    </NotePane>
                </VStack>
            </HStack>

            <Text>
                As you explore the area around your home, you will discover, hidden passages, caves, groves, streams,
                and ruins. You may find the forest eerily quiet, but sooner or later you'll be ambushed by hostile
                monsters ready to engage you in combat.
            </Text>
        </VStack>
    )
}

export default PlayersGuideFirstSteps
