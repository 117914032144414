import React from 'react'
import { Badge, Box, Text } from '@chakra-ui/react'

const ReleaseNoteBullet = ({ type, text }) => {
    const badgeColors = {
        FIX: 'red',
        NEW: 'green',
    }
    return (
        <li>
            <Badge colorScheme={badgeColors[type]}>{type}</Badge> {text}
        </li>
    )
}

const ReleaseNotes = ({ version }) => {
    if (!version) {
        return <></>
    }

    console.log('Release notes for version', version)

    const remoteVersion = version.remoteVersion
    const notes = remoteVersion.notes
    return (
        <Box mt={4}>
            <Text color='white' fontSize='xl'>
                <u>{remoteVersion.version + ' Release Notes'}</u>
            </Text>
            <Text color='white'>
                {notes
                    .split('\n')
                    .filter((s) => s.trim().length > 0)
                    .map((s, i) => {
                        const type = s.substr(0, 3)
                        const note = s.substr(4).trim()
                        return (
                            <span key={i}>
                                <ReleaseNoteBullet type={type} text={note} />
                            </span>
                        )
                    })}
            </Text>
            {remoteVersion.beta && (
                <Text color='white' mt={4}>
                    <Badge colorScheme='yellow'>WARNING</Badge> This is an <b>BETA</b> release. You may wish to remain
                    on your current version. Please report any bugs you find.
                </Text>
            )}
            {remoteVersion.breaking && (
                <Text color='white' mt={4}>
                    <Badge colorScheme='yellow'>WARNING</Badge> This is a <b>BREAKING</b> release. Old games may not
                    load correctly. You may wish to finish your current game before updating.
                </Text>
            )}
        </Box>
    )
}

export { ReleaseNotes }
