import React from 'react'
import { Button, Tooltip, VStack } from '@chakra-ui/react'

import 'remixicon/fonts/remixicon.css'
import { HederaIcon } from './HederaIcon'

function Toolbar({
    position,
    showFullscreen,
    onFullscreen,
    onHelp,
    onBug,
    onDonate,
    onSocial,
    onWallet,
    selected,
    top,
}) {
    const ToolbarButton = ({ icon, label, onClick, isSelected }) => (
        <Tooltip label={label} aria-label={label}>
            <Button variant={isSelected ? 'solid' : 'link'} m={1} onClick={onClick}>
                <i className={icon}></i>
            </Button>
        </Tooltip>
    )

    return (
        <VStack
            style={{
                ...(position === 'left' ? { left: 0 } : { right: 0 }),
                position: 'fixed',
                top: top || 0,
                padding: 8,
                margin: 0,
            }}
            spacing={2}
        >
            {/* FULLSCREEN */}
            {showFullscreen && (
                <ToolbarButton icon='ri-fullscreen-line ri-lg' label='Fullscreen' onClick={onFullscreen} />
            )}

            {/* HELP */}
            <ToolbarButton
                icon='ri-question-line ri-lg'
                label='Help'
                onClick={onHelp}
                isSelected={selected === 'help'}
            />

            {/* BUG */}
            {onBug && (
                <ToolbarButton
                    icon='ri-star-line ri-lg'
                    label='Feedback'
                    onClick={onBug}
                    isSelected={selected === 'bug'}
                />
            )}

            {/* DONATE */}
            <ToolbarButton
                icon='ri-btc-line ri-lg'
                label='Support further development'
                onClick={onDonate}
                isSelected={selected === 'donate'}
            />

            <ToolbarButton
                icon='ri-share-line ri-lg'
                label='Connect with Mysterious Castle'
                onClick={onSocial}
                isSelected={selected === 'social'}
            />

            {/* HEDERA */}
            {/* {onWallet && (
                <Tooltip label={'Connect Wallet'} aria-label={'Connect Wallet'}>
                    <Button variant={'link'} m={1} onClick={onWallet}>
                        <HederaIcon fontSize={'1.1em'} />
                    </Button>
                </Tooltip>
            )} */}
        </VStack>
    )
}

export { Toolbar }
