import React from 'react'

import { Box, Button, Container, Stack, Text, useColorModeValue, VisuallyHidden } from '@chakra-ui/react'

/* TODO: Add logo and social buttons.

const Logo = (props) => {
    return <svg height={32} viewBox='0 0 120 28' xmlns='http://www.w3.org/2000/svg' {...props}></svg>
}

const SocialButton = ({ children, label, href }) => {
    return (
        <Button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </Button>
    )
}
*/

export default function Footer() {
    return (
        <Box
            bg={`linear-gradient(to bottom, #0f0f0f, #020202)`}
            style={{
                transition: 'ease-in-out 1s',
            }}
        >
            <Container
                as={Stack}
                maxW={'6xl'}
                py={4}
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                justify={{ base: 'center', md: 'space-around' }}
                align={{ base: 'center', md: 'center' }}
            >
                {/* <Logo /> */}

                <Text color='gray.300' fontWeight={200} fontSize={'xs'}>
                    © 2024 Jeremy Jurksztowicz. All rights reserved
                </Text>

                {/* 
                <Stack direction={'row'} spacing={6}>
                    <SocialButton label={'Twitter'} href={'#'}></SocialButton>
                    <SocialButton label={'YouTube'} href={'#'}></SocialButton>
                    <SocialButton label={'Instagram'} href={'#'}></SocialButton>
                </Stack> 
                */}
            </Container>
        </Box>
    )
}
