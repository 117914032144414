/* eslint no-undef: "warn" */
import React, { useEffect, useState } from 'react'

// Main Chakra UI components:
import {
    Box,
    Button,
    Center,
    HStack as ChakraHStack,
    Image,
    Link,
    List,
    ListItem,
    OrderedList,
    Text,
    UnorderedList,
    VStack,
} from '@chakra-ui/react'

import 'remixicon/fonts/remixicon.css'

import useMatchMedia from './MatchMedia'
import {
    actionColors,
    cursorColors,
    ActionBar,
    HStack,
    NotePane,
    SectionHeader,
    SubsectionHeader,
    RadialImage,
    RectImage,
    style,
} from './PlayersGuideUtil'

const PlayersGuidePoweringUp = () => {
    const isDesktopResolution = useMatchMedia('(min-width:992px)', true)

    return (
        <VStack mt={style.sectionSpacing} spacing={style.defaultSpacing}>
            <Center h={'75px'}>
                <SectionHeader>Exploring and Powering Up</SectionHeader>
            </Center>
            <HStack>
                <Text style={{ width: '60%' }}>
                    Mysterious Castle is a game without classes or levels. All character progression is achieved through
                    collecting equipment, reading books, drinking potions, or obtaining <i>blessings</i>. Items can be
                    taken from slain enemies, but most often you will find interesting items in <i>dungeons</i>. The
                    ruins of dungeons can be found scattered throughout the overworld, but the best items can be found
                    in underground dungeons, whose entrances can be found hidden in every corner of the overworld.
                    <br />
                    <br />
                    Books, which can either permanently increase a stat or skill; or teach a character a new spell, are
                    almost always found in dungeon libraries and shrines. The sooner you find a dungeon to explore, the
                    sooner you can power up your party!
                    <br />
                    <br />
                    The most powerful items in the game, are hidden in the deepest dungeons.
                </Text>
                <RectImage
                    src='/playersguide/treasure-vault.png'
                    alt='cast spell menu'
                    maxWidth={'40%'}
                    heading={'A treasure vault with an obelisk in it, a very lucky find!'}
                />
            </HStack>
            <NotePane style={{ width: '100%' }}>
                <i class='ri-information-line ri-xl'></i> Item based progression means that combat has no inherent
                benefits to you, unless it provides access to new items to collect. You must carefully weigh whether you
                want to spend the <i>hitpoints</i> and <i>mushrooms</i> necessary to defeat the foes before you.
                <br />
                <br />
                Mysterious Castle was designed this way in order to avoid disincentivizing non-combat gameplay, but as
                of yet, has not really provided a lot of alternatives to fighting. I hope to incorporate additional
                tactics such as pick-pocketing and negotiation to allow extremely varied gameplay styles in future
                versions.
            </NotePane>
            <SubsectionHeader>Finding and Exploring Dungeons</SubsectionHeader>
            <HStack>
                <Text maxWidth={'60%'}>
                    The overworld of Mysterious Castle is a treacherous forest maze filled with secret alcoves and
                    entrances to dungeons. These caves and groves often contain treasure, fruit, mushrooms, and stairs
                    leading downwards. The entrances to these areas are often deviously hidden behind trees. Your
                    party's characters can <i>spot</i> these entrances, spotted entrances are highlighted in{' '}
                    <b style={{ color: cursorColors.green }}>green</b>.
                    <br />
                    <br />
                    Characters will also <i>spot</i> containers with items in them, and corpses with special items.
                    <br />
                    <br />
                    The character with the highest <i>spot</i> skill in your party will automatically make these{' '}
                    <i>spot</i> checks as you explore, so remember to equip them with any spot improving items, and
                    train them with any books that improve the <i>spot</i> skill.
                </Text>
                <RectImage
                    src='/playersguide/hidden-grove.gif'
                    alt='dungeon entrance'
                    heading={'A secret grove is hidden behind this tree'}
                />
            </HStack>
            {/* <NotePane style={{ maxWidth: '50%' }}> */}
            <HStack>
                <RectImage src='/playersguide/minimap-hidden.png' alt='dungeon entrance' />
                <Text maxWidth={'80%'}>
                    You should also keep an eye out for inconsistencies in your <i>minimap</i> and <i>world map</i>. If
                    there is an unexplained gap in the treeline, then it's likely that the entrance of an alcove is
                    hidden there. If you feel stuck in a dead end, or surrounded by enemies too powerful to defeat, look
                    at your <i>world map</i> and see if you've passed by any suspicious looking areas.
                </Text>
            </HStack>
            {/* </NotePane> */}
            <NotePane>
                <i class='ri-information-line ri-xl'></i> Every spellcaster starts the game knowing the <i>vision</i>{' '}
                spell. With it you can peer behind dungeon walls and thick forest, but <u>not</u> cave walls. It can be
                a powerful way to find hidden alcoves and scout for enemies, but use it sparingly or you will run out of
                white mushrooms!
                <RectImage src='/playersguide/vision-spell.gif' alt='dungeon entrance' style={{ width: '100%' }} />
            </NotePane>
            <RectImage
                src='/playersguide/dungeon-entrance.png'
                alt='dungeon entrance'
                heading={'The entrance to an underground dungeon, or cave'}
            />
        </VStack>
    )
}

export default PlayersGuidePoweringUp
