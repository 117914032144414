import React, { useEffect, useState } from 'react'
import './App.css'

import {
    Box,
    Button,
    ChakraProvider,
    Collapse,
    Fade,
    Flex,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverCloseButton,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react'
import { Link as RouteLink, BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import ReactPlayer from 'react-player'

import 'remixicon/fonts/remixicon.css'

import Home from './Home'
import Game from './Game'
import PlayersGuide from './PlayersGuide'
import Privacy from './Privacy'
import Footer from './Footer'
import * as WalletConnect from './WalletConnect'
import setBodyColor from './BodyColor'

// Bootstrap collectables to Emscripten Binary:
import './Collectables'

import Analytics from './Analytics'
Analytics.initialize()

function App() {
    const [runGame, setRunGame] = useState(false)
    const [showGame, setShowGame] = useState(false)
    const [showBanner, setShowBanner] = useState(true)
    const [musicPlaying, setMusicPlaying] = useState(false)
    const [dismissedPreamble, setDismissedPreamble] = useState(false)

    const onGameStart = () => {
        setShowBanner(false)
        setDismissedPreamble(true)
    }

    const onGameExit = () => {
        setShowGame(false)
        setShowBanner(true)
    }

    const commonStyle = {
        backgroundColor: 'black',
    }

    useEffect(() => {
        ;(async () => {
            // await WalletConnect.init()
        })()
    }, [])

    // Our pseudo-component that the router handles. It communicates with App
    // to manage rendering and controlling the game.
    const GameWrapper = () => {
        const location = useLocation()
        useEffect(() => {
            Analytics.pageview(location, 'Game')
            const inGame = location.pathname === '/game'
            setShowGame(inGame)
            setRunGame(inGame)
            if (dismissedPreamble) {
                setShowBanner(false)
            }
        }, [location])

        return (
            <Fade in={true} transition={{ enter: { duration: 1.5 } }}>
                <VStack
                    style={{
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        padding: 8,
                        margin: 0,
                    }}
                    spacing={2}
                >
                    {/* BACK */}
                    <Tooltip label='Back to homepage' aria-label='Back to homepage'>
                        <Link as={RouteLink} to='/' color='green.300'>
                            <Button variant='link' m={1} onClick={() => onGameExit()}>
                                <i className='ri-arrow-left-line'></i>
                            </Button>
                        </Link>
                    </Tooltip>
                </VStack>
            </Fade>
        )
    }

    return (
        <ChakraProvider>
            <Collapse in={showBanner} animateOpacity={false}>
                <Flex style={{ ...commonStyle, height: '200px' }}>
                    <div
                        style={{
                            imageRendering: 'pixelated',
                            backgroundImage:
                                'linear-gradient(to bottom, rgba(0, 0, 0, 0), #0f0f0fff), url("/banner01.png")',
                            width: '100%',
                            height: 'auto',
                            backgroundSize: 'cover',
                        }}
                    />
                </Flex>
            </Collapse>
            <Box
                style={{
                    height: '30px',
                    width: '40px',
                    position: 'fixed',
                    bottom: '0',
                    right: '0',
                    zIndex: '999999',
                    margin: '8px',
                }}
            >
                <Popover isLazy={'true'} lazyBehavior={'keepMounted'} closeOnBlur={false}>
                    {({ isOpen, onClose }) => (
                        <>
                            <PopoverTrigger>
                                <Box display='inline-block'>
                                    <Tooltip
                                        label={'Listen to awesome fantasy music'}
                                        aria-label={'Listen to awesome fantasy music'}
                                    >
                                        <Button
                                            variant='link'
                                            style={{
                                                animation: musicPlaying
                                                    ? 'FadeInOut 3.0s ease-in-out infinite'
                                                    : 'none',
                                            }}
                                        >
                                            <i className='ri-music-2-line ri-lg'></i>
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </PopoverTrigger>

                            <PopoverContent
                                width={400}
                                height={250}
                                color={'gray.300'}
                                bg={'#222222'}
                                borderColor={'#222222'}
                                style={{
                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
                                    justifyContent: 'space-between',
                                    rounded: 5,
                                    padding: 5,
                                    marginLeft: 10,
                                    marginRight: 10,
                                }}
                            >
                                {/* <PopoverArrow bg={'#222222'} borderColor={'#222222'} /> */}
                                <PopoverCloseButton />
                                <PopoverBody>
                                    <ReactPlayer
                                        width={'340px'}
                                        height={'191px'}
                                        url='https://www.youtube.com/embed/videoseries?si=ndmC8VH1Ypf_zz53&amp;list=PL1nAweQ-4aeYfYqRCKVty_gScc9A6FCn4'
                                        onPlay={() => {
                                            setMusicPlaying(true)
                                        }}
                                        onPause={() => {
                                            setMusicPlaying(false)
                                        }}
                                        onEnded={() => {
                                            setMusicPlaying(false)
                                        }}
                                    />
                                    <Text fontSize={'xs'} mt={2}>
                                        Listen to some great fantasy music while you play
                                    </Text>
                                </PopoverBody>
                            </PopoverContent>
                        </>
                    )}
                </Popover>
            </Box>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='/game' element={<GameWrapper />}></Route>
                    <Route
                        path='/players-guide'
                        element={
                            <PlayersGuide
                                onEnter={() => {
                                    setShowBanner(false)
                                    setBodyColor({ color: '#0f0f0f' })
                                }}
                                onExit={() => {
                                    setShowBanner(true)
                                    setBodyColor({ color: '#020202' })
                                }}
                            />
                        }
                    ></Route>
                    <Route path='/privacy' element={<Privacy></Privacy>}></Route>
                </Routes>
            </BrowserRouter>
            {/* Game lives outside router so we control the mounting and rendering. */}
            <div style={{ display: runGame && showGame ? 'block' : 'none' }}>
                <Game shouldStart={runGame} onGameStart={onGameStart} shouldHide={!runGame} />
            </div>
            <Footer />
        </ChakraProvider>
    )
}

export default App
