import React from 'react'
import { Box, Button, Icon, Link, ListItem, OrderedList, Text, UnorderedList } from '@chakra-ui/react'

import { HederaIcon } from './HederaIcon'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import 'remixicon/fonts/remixicon.css'

function SocialInfo() {
    const linkColor = '#718096'

    const textStyle = { marginBottom: 5 }

    return (
        <Box style={{ color: 'white', overflowY: 'scroll', padding: 5, paddingTop: 0 }}>
            <Text style={textStyle}>
                Follow me on{' '}
                <Link href='https://twitter.com/eclectocrat' isExternal color='green.300'>
                    <i className='ri-twitter-x-line'></i>/<i className='ri-twitter-line'></i>{' '}
                    <ExternalLinkIcon mx='2px' />
                </Link>
                to get the latest news and updates
            </Text>
            <iframe
                frameborder='0'
                src='https://itch.io/embed/2603810?bg_color=1f1f1f&amp;fg_color=eeeeee&amp;link_color=fa5c5c&amp;border_color=404040'
                width='552'
                height='167'
            >
                <a href='https://eclectocrat.itch.io/mysterious-castle'>Mysterious Castle by eclectocrat</a>
            </iframe>
            <Text style={textStyle}>
                I'll occasionally post some videos on{' '}
                <Link href='https://www.youtube.com/@jjurksztowicz' isExternal color='green.300'>
                    YouTube <i className='ri-youtube-line'></i> <ExternalLinkIcon mx='2px' />
                </Link>
            </Text>
            <Text style={textStyle}>
                Chat with me and other players on{' '}
                <Link href='https://discord.gg/ajYYvcvaYK' isExternal color='green.300'>
                    Discord <i className='ri-discord-line'></i> <ExternalLinkIcon mx='2px' />
                </Link>
            </Text>
            <iframe
                title='discord'
                src='https://discord.com/widget?id=1198772015079895112&theme=dark'
                width='100%'
                height='500'
                allowtransparency='true'
                frameBorder='0'
                sandbox='allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts'
                style={{ marginBottom: 10, marginTop: 10 }}
            ></iframe>
            <a
                href='https://www.indiedb.com/games/mysterious-castle'
                title='View Mysterious Castle on IndieDB'
                target='_blank'
            >
                <img src='https://button.indiedb.com/rating/medium/games/15009.png' alt='Mysterious Castle' />
            </a>
            {/* Bottom padding: */}
            <Box style={{ height: 200 }} />
        </Box>
    )
}

export { SocialInfo }
