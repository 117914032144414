/* eslint no-undef: "warn" */
import React, { useEffect, useState } from 'react'

// Main Chakra UI components:
import {
    Box,
    Button,
    Center,
    HStack as ChakraHStack,
    Image,
    Link,
    List,
    ListItem,
    OrderedList,
    Text,
    UnorderedList,
    VStack,
} from '@chakra-ui/react'

import 'remixicon/fonts/remixicon.css'

import useMatchMedia from './MatchMedia'
import {
    actionColors,
    cursorColors,
    ActionBar,
    HStack,
    NotePane,
    SectionHeader,
    SubsectionHeader,
    RadialImage,
    RectImage,
    style,
} from './PlayersGuideUtil'

const PlayersGuideMagic = () => {
    const isDesktopResolution = useMatchMedia('(min-width:992px)', true)

    return (
        <VStack mt={style.sectionSpacing} spacing={style.defaultSpacing}>
            <Center h={'75px'}>
                <SectionHeader>Magic</SectionHeader>
            </Center>
            <Text maxWidth={style.defaultColumnWidth}>
                Spells are powerful weapons that can swing the tide of battle in an instant. A seemingly impossible
                fight can be won with a well timed spell to weaken, blind, or damage your enemies. But spells are
                prepared using mushrooms, limited resources that must be rationed.
                <br />
                <br />
                Most characters can't cast spells, but you should have started the game with at least one proficient
                spellcaster. <i>Swipe from left to right</i> or press the right arrow key{' '}
                <i className='ri-arrow-right-line'></i> to open your spellbook. You can see the mushrooms your party has
                collected, and tap on the characters to see what spells they know, and which spells they've prepared.
            </Text>
            <HStack maxWidth={'100%'}>
                <VStack maxWidth={style.defaultColumnWidth}>
                    <NotePane>
                        <Text>
                            <i class='ri-number-1'></i> The spells you <i>know</i> are displayed in the top part of the
                            spellbook. Tap on the level tabs to select the spell level to view, if you don't know any
                            spells of that level the view will be empty.
                        </Text>
                    </NotePane>
                    <NotePane>
                        <Text>
                            <i class='ri-number-2'></i> You can select a different characters spellbook by tapping on
                            them. Because spells are limited resources, it's common to have only one or two spellcasters
                            in your party.
                        </Text>
                    </NotePane>
                    <NotePane>
                        <Text>
                            <i class='ri-number-3'></i> You can see all of the party's <i>mushrooms</i> here, as well as
                            recipe costs <i>(see below)</i>.
                        </Text>
                    </NotePane>
                    <NotePane>
                        <Text>
                            <i class='ri-number-4'></i> The characters prepared spells are displayed at the bottom.
                            First level spells are displayed in the first column, second level spells in the second
                            column, and third level spells in the last column.
                        </Text>
                    </NotePane>
                </VStack>
                <RectImage
                    src='/playersguide/spellbook.png'
                    alt='spellbook'
                    maxWidth='100%'
                    heading={'A gnomes spellbook with a variety of powerful spells prepared'}
                />
            </HStack>
            <SubsectionHeader>Preparing Spells</SubsectionHeader>
            <HStack>
                <Text>
                    Spells in Mysterious Castle must be <i>prepared</i> before you can cast them. Every spell has a
                    recipe cost in mushrooms, if you don't have enough mushrooms then that spell can't be prepared. Thus
                    the spells you prepare and cast must be carefully balanced so that you have the appropriate spells
                    ready for any enemies you encounter. Early in the game it's wise to ration mushrooms, so that when
                    you discover new spells later, you have the resources to prepare and cast them.
                    <br />
                    <br />
                    Spells can also be <i>unprepared</i> at will, with no cost. That is, all the mushrooms you used to
                    prepare the spell are returned to you. So feel free to prepare and unprepare spells as often as you
                    like in order to find a good balance of spells for your party.
                    <br />
                    <br />
                    You can only <i>prepare</i> and <i>ready</i> spells when you are not in combat.
                </Text>
            </HStack>
            <HStack>
                <VStack maxWidth={isDesktopResolution ? '33%' : '80%'}>
                    <NotePane style={{ height: isDesktopResolution ? '180px' : '' }}>
                        <Text>
                            In order to prepare a spell, tap on it's icon. You'll see it's mushroom cost in the mushroom
                            box. Tap on it again to <i>prepare</i> the spell.
                        </Text>
                    </NotePane>
                    <RectImage src='/playersguide/spell-cost-01.png' alt='spellbook' style={{ height: '456px' }} />
                </VStack>
                <VStack maxWidth={isDesktopResolution ? '33%' : '80%'}>
                    <NotePane style={{ height: isDesktopResolution ? '180px' : '' }}>
                        <Text>
                            The mushrooms required for the spell will have numbers in blue above them indicating the
                            amount required for the spell.
                        </Text>
                    </NotePane>
                    <RectImage src='/playersguide/spell-cost-02.png' alt='spellbook' style={{ height: '456px' }} />
                </VStack>
                <VStack maxWidth={isDesktopResolution ? '33%' : '80%'}>
                    <NotePane style={{ height: isDesktopResolution ? '180px' : '' }}>
                        <Text>
                            If you don't have enough mushrooms to prepare the spell, the number will be red, but you can
                            still <i>ready</i> the spell.
                        </Text>
                    </NotePane>
                    <RectImage src='/playersguide/spell-cost-03.png' alt='spellbook' style={{ height: '456px' }} />
                </VStack>
            </HStack>
            <Text>
                <i>Prepared</i> spells appear as buttons at the bottom of the spellbook. You can tap on a prepared spell
                to <i>unprepare</i> it and reclaim any mushrooms you used to prepare it.
                <br />
                <br />
                <i>Readied</i> spells appear as white greyed out buttons at the bottom of the spellbook. Spells are
                automatically readied after you cast them. A readied spell is immediately prepared when you find enough
                mushrooms to prepare it. This ensures that even if you forget to prepare spells after a battle, or you
                are very low on mushrooms, you will always have some spells prepared when you encounter enemies.
                <br />
                <br />
                Just like <i>prepared</i> spells, you can tap on a <i>readied</i> spell to <i>unready</i> it.
            </Text>
            <SubsectionHeader>Casting Spells</SubsectionHeader>
            <Text>
                Once you have prepared some spells, it's time to unleash them in battle. In order to cast a spell you
                have prepared you can bring up the <i>cast spell menu</i> and select the spell to cast. You can show the{' '}
                <i>cast spell menu</i> in one of three ways:
            </Text>
            <Text maxWidth={style.defaultColumnWidth}>
                <ol>
                    <li style={{ margin: '10px' }}>
                        When in combat, tap on the currently active character. If they have any spells prepared then you
                        will see a <i>cast spell</i> option in their context menu.
                        <RectImage src='/playersguide/caster-menu.png' alt='context menu spellcaster' />
                        Outside of combat, you can tap on any party member to bring up their context menu.
                    </li>
                    <li style={{ margin: '10px' }}>
                        Tap on the quick action that looks like a magical book.
                        <RectImage src='/playersguide/quick-action-bar.png' alt='quick action spellcaster' />
                        Characters who do not have any spells prepared, and spellcasters who do not have anough action
                        points to cast any spells they have prepared will not have a cast spell quick action.
                    </li>
                    <li style={{ margin: '10px' }}>
                        <b>WEB VERSION ONLY</b> Press the <b>SHIFT</b> key to bring up the <i>cast spell menu</i>.
                    </li>
                </ol>
            </Text>
            <HStack>
                <Text maxWidth={'65%'}>
                    Once the <i>cast spell menu</i> is displayed you'll see all the spells that character has prepared,
                    as well as a <i>cancel</i> button and a <i>retain</i> counter. As expected, <i>cancel</i> dismisses
                    the <i>cast spell menu</i>.
                    <br />
                    <br />
                    The characters prepared spells will be displayed, with each spell level in it's own column. If you
                    do not have any spells of a particular level prepared, that column won't be displayed. Each prepared
                    spell has an icon next to it indicating whether it is a <i>free</i>, <i>move</i>, <i>partial</i>, or{' '}
                    <i>full</i> action. Spells for which you do not have enough action points are greyed out.
                    <br />
                    <br />
                    Tap on a spell in order to cast it. Almost all spells are <i>targetted</i> actions{' '}
                    <i>(see combat section, above)</i>, and will require you to select a target or area to cast the
                    spell on. Once the spell is cast, it will be removed from the <i>cast spell menu</i>, unless you
                    manage to <i>retain</i> it <i>(more on that below)</i>.
                </Text>
                <RectImage
                    src='/playersguide/cast-spell-menu.png'
                    alt='cast spell menu'
                    heading={
                        "A large set of prepared spells, most of which the character doesn't have enough action points to cast"
                    }
                />
            </HStack>
            <NotePane>
                <HStack>
                    <Text>
                        <i class='ri-information-line ri-xl'></i> Casting a spell leaves a trace of magical energy
                        lingering in the air. There is a chance that your spellcaster can draw on that energy to{' '}
                        <i>retain</i> a prepared spell after casting it.
                        <br />
                        <br />
                        Everytime <i>any</i> spell <i>(i.e. yours or an enemy's)</i> is cast in the vicinity of your
                        party, a retain check is performed. One of two outcomes occurs:
                        <ul>
                            <li style={{ margin: '10px' }}>
                                If the retain check is equal to or below the current <i>retain level</i>, then the
                                prepared spell is <b>not</b> unprepared after casting, and can be cast again. The retain
                                level is reduced by the amount of mushrooms required to prepare that spell.
                            </li>
                            <li style={{ margin: '10px' }}>
                                If the retain check is higher than the current <i>retain level</i>, then the{' '}
                                <i>retain level</i> increases by the amount of mushrooms required to <i>prepare</i> that
                                spell.
                            </li>
                        </ul>
                        Every round the <i>retain level</i> decreases by one, as the magical energy dissipates into the
                        aether.
                        <br />
                        <br />
                        As more and more spells are cast, it becomes less costly to cast even more spells. During
                        battles with magical foes, or if you have multiple members of your party casting spells, the{' '}
                        <i>retain level</i> can increase very quickly. <u>Be warned!</u> This effect is a double-edged
                        sword which also enables your enemies to cast more spells against you!
                        <br />
                        <br />
                        <i class='ri-information-line ri-xl'></i> Be on the lookout for items <i>(especially rings)</i>{' '}
                        which grant a retain bonus, as they can free your spellcasters to unleash powerful spells more
                        often without worrying about wasting mushrooms.
                    </Text>
                </HStack>
            </NotePane>
            <SubsectionHeader>Learning New Spells</SubsectionHeader>
            <HStack>
                <VStack style={{ maxWidth: '50%' }}>
                    <Text>
                        New spells can be learned, and known spells can be improved, by studying tomes that are found in
                        dungeons. When you tap on a tome, if any characters are able to learn or level up that spell, a{' '}
                        <i>learn spell</i> button will show up in it's context menu. When you tap on <i>learn spell</i>{' '}
                        all characters able to learn or level up that spell will be highlighted, characters highlighted
                        in <b style={{ color: cursorColors.green }}>green</b> can learn that spell, while characters
                        highlighted in <b style={{ color: cursorColors.yellow }}>yellow</b> already know that spell and
                        can level it up.
                    </Text>
                    <HStack>
                        <Text maxWidth={'50%'}>
                            When a character levels up a spell it becomes more powerful, dealing more damage or being
                            harder to resist. The max level for most spells is 3, but some spells like <i>quicken</i>{' '}
                            have a static effect, and thus can't be leveled up at all. The level of a spell is displayed
                            in the spellbook as dots above the spell name.
                        </Text>
                        <RectImage
                            src='/playersguide/level-2-spell.png'
                            alt='level 2 spell'
                            heading={'A level 2 bolt+ spell'}
                        />
                    </HStack>
                </VStack>
                <RectImage src='/playersguide/level-up-spell.gif' alt='level up spell' />
            </HStack>
        </VStack>
    )
}

export default PlayersGuideMagic
